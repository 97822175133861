/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';

import logoBlock from '../../logo-emblem.png';

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import './FAQ.css'

const FAQ = () => {
  const [activeQuestionState, setActiveQuestionState] = useState({ group: null, question: null });

  const { group: activeGroup, question: activeQuestion } = activeQuestionState;

  const setActiveQuestion = (newActiveQuestion) => {
    setActiveQuestionState(newActiveQuestion);
  };

  const questions = [
    {
      category: 'Getting Started',
      faqs: [
        {
          question: 'What is Revibe, and what does it aim to achieve?',
          answer: 'Revibe is a platform committed to \'Refreshing Our World\'. We provide a space for sharing ideas, experiences, and engaging in conversations.'
        },
        {
          question: 'How does Revibe use technology?',
          answer: 'Revibe is about building connections and nurturing unity. Through thoughtful design and interactive features, we aim to create a platform that reflects the values and desires of our users. It\'s a space where technology meets humanity.'
        },
        {
          question: 'How can I actively participate in with Revibe?',
          answer: 'We encourage and value your active participation in Revibe. You can share ideas and engage in conversations. Your insights help us.'
        },
        {
          question: 'How do I sign up, and what can I do on Revibe?',
          answer: 'You can create an account on Revibe. If you want to create a group, you can plan your events and include them on our platform. Be sure to follow our guidelines.'
        }
      ],
    },
    {
      category: 'Group Collaboration',
      faqs: [
        {
          question: 'How does Revibe ensure safety and respect within the platform?',
          answer: 'Revibe strives to maintain a respectful and uplifting digital platfrom. We have guidelines in place that promote healthy interactions. If you encounter any concerns, you can contact support.'
        },
        {
          question: 'Can I contribute to Revibe\'s growth and development?',
          answer: 'Absolutely! Your feedback, ideas, and engagement are crucial to Revibe\'s evolution. Whether you own a local business or simply attend events, your contributions shape the platform. Together, we\'ll refresh our world.'
        },
        {
          question: 'What kind of content can I share on Revibe?',
          answer: 'You can share ideas, experiences, and insights that align with our policies. We welcome various perspectives and encourage respectful dialogue.'
        },
        {
          question: 'How do groups work on Revibe?',
          answer: 'We hope to grow Revibe groups in the future. You can create up to 10 groups for a single account. Each group can have a subscription. Transferring group ownership is currently not possible.'
        }
      ],
    },
    {
      category: 'Safety and Privacy',
      faqs: [
        {
          question: 'How does Revibe protect my privacy?',
          answer: 'Your privacy is essential to us. Revibe adheres to robust privacy standards to ensure your information is handled with care and confidentiality. For more details, you can review our privacy policy.'
        },
        {
          question: 'How do I report inappropriate content or behavior on Revibe?',
          answer: 'If you encounter content or behavior that goes against our guidelines, please report it to us immediately by sending us a message: support@revibe.life. We take these reports seriously and will act promptly.'
        },
        {
          question: 'How does Revibe handle privacy and cookies?',
          answer: 'At Revibe, we focus on protecting our users by securing privacy. You can learn more about our practices by reviewing our cookie and privacy policies.'
        }
      ],
    },
    {
      category: 'Features and Navigation',
      faqs: [
        {
          question: 'Can local businesses participate in Revibe?',
          answer: 'Yes, local businesses are a vital part of our platform, and we welcome their participation. Revibe offers opportunities for collaboration and engagement.'
        },
        {
          question: 'How does Revibe support growth?',
          answer: 'Revibe aims to be more than just a social platform. We strive to support growth through active engagement, collaboration, and the fostering of meaningful connections.'
        },
        {
          question: 'How do I navigate and find content that interests me on Revibe?',
          answer: 'Revibe offers a main search filter that allows you to easily explore content across various groups. We actively work towards improving our local search experience.'
        },
        {
          question: 'How can I access Revibe on different devices?',
          answer: 'Revibe is accessible via an internet browser on various devices, including desktops, laptops, tablets, and smartphones. Our platform is designed to provide a seamless experience across different platforms. Simply visit https://revibe.life'
        }
      ],
    },
    {
      category: 'Support and Feedback',
      faqs: [
        {
          question: 'How can I find out about new features or updates on Revibe?',
          answer: 'We are constantly working to improve Revibe. As Revibe evolves, we hope we can share updates and communicate with you directly within our forums.'
        },
        {
          question: 'What support is available if I need help using Revibe?',
          answer: 'If you have any questions or need help with Revibe, please send us a message: support@revibe.life. Your feedback and inquiries are important to us.'
        },
        {
          question: 'How can I provide feedback on Revibe?',
          answer: 'Your insights are essential to the continuous improvement of Revibe. Reach out to us by sending us a message: support@revibe.life with your thoughts, ideas, and feedback. Consider starting a discussion in our forums, too.'
        }
      ],
    },
    {
      category: 'Accessibility and Collaboration',
      faqs: [
        {
          question: 'Is Revibe suitable for all age groups?',
          answer: 'Revibe is primarily designed for individuals aged 18 and over. We are committed to providing a platform that aligns with our mission. We recommend parental guidance for younger users.'
        },
        {
          question: 'How can I collaborate with Revibe as a business or individual?',
          answer: 'We welcome collaboration from businesses, organizations, and individuals that align with our mission. Please send us a message: support@revibe.life to discuss potential partnerships and collaborations.'
        },
        {
          question: 'How accessible is Revibe to people?',
          answer: 'Accessibility is a key focus at Revibe. We are continuously working to enhance our user-centered features, and we welcome your feedback to help us improve in this area.'
        },
        {
          question: 'What language options are available on Revibe?',
          answer: 'Currently, Revibe is available in English. As Revibe changes, we aim to provide additional language options in the future. If you have any requests or suggestions, please feel free to send us a message: support@revibe.life.'
        }
      ],
    },
  ];

  return (
    <>
      <div className='faq_container'>
        <div className='faqs'>
          <h1 className="legal-title">FAQs</h1>
          {questions.map((section, index) => (
            <div className="card faq_group" key={index}>
              <h3>{section.category}</h3>
              {section.faqs.map((faq, qIndex) => (
                <div key={qIndex}>
                  <p onClick={() => setActiveQuestion(index === activeGroup && qIndex === activeQuestion ? { group: null, question: null } : { group: index, question: qIndex })}>
                    <strong className="faq_question">{faq.question}</strong>
                  </p>
                  {activeGroup === index && activeQuestion === qIndex && <p>{faq.answer}</p>}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      
      <div className='event_nav' style={{zIndex: 0}}>
          <Link to={`/resources/`}>
            <div className="fa-button">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    size={"xl"}
                />
            </div>
          </Link>
      </div>
    </>
  );
};

export default FAQ;
