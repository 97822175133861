/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';

import { Link, useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBinoculars } from '@fortawesome/free-solid-svg-icons';

const SearchNav = ({ search, handleSearch }) => {
  return (
    <div className='search_nav'>
      <Link to="/" className='fa-button search_nav-back'>
          <FontAwesomeIcon
              icon={faArrowLeft}
              size="xl" />
      </Link>
      <div className="search_input-container">
        <input type="text" value={search} onChange={handleSearch} placeholder="Explore vibes" />
      </div>
      <Link to="/query" className='fa-button search_nav-next'>
          <FontAwesomeIcon
              icon={faBinoculars}
              size="xl" />
      </Link>
    </div>
  );
};
  
export default SearchNav;
  