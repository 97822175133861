/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';

import { Link, useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCalendarDays, faMagnifyingGlass, faCircleUser } from '@fortawesome/free-solid-svg-icons';

import './EventNav.css';

import config from '../../config';

const forumURL = config.createApiUrl('forum', `/`, config.URL_TYPES.BASE);
const identityURL = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);

const AnimatedNavLink = ({ iconIndex }) => {
    const location = useLocation();

    const getButtonClass = (targetPath) => {
        return `fa-button event_button${location.pathname === targetPath ? '_current' : ''}`;
    };

    const [beatStates, setBeatStates] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
    });

    const animateIcon = (index) => {
        setBeatStates((prevBeatStates) => ({
          ...prevBeatStates,
          [index]: true,
        }));

        setTimeout(() => {
          setBeatStates((prevBeatStates) => ({
            ...prevBeatStates,
            [index]: false,
          }));
        }, 800); // 800ms for the animation duration
    };

    const iconLookup = {
        0: {
            icon: faCalendarDays,
            to: "/",
            targetPath: "/",
        },
        1: {
            icon: faComments,
            to: `${forumURL}`,
            targetPath: null,
        },
        2: {
            icon: faMagnifyingGlass,
            to: "/search",
            targetPath: "/search",
        },
        3: {
            icon: faCircleUser,
            to: `${identityURL}user-profile`,
            targetPath: null,
        },
    }

    const currentIcon = iconLookup[iconIndex]; // Corrected property access

    return (
        <Link
            to={currentIcon.to}
            className={getButtonClass(currentIcon.targetPath)}
            onClick={() => animateIcon(iconIndex)}
            onMouseEnter={() => animateIcon(iconIndex)}
        >
            <FontAwesomeIcon
                icon={currentIcon.icon}
                beat={beatStates[iconIndex]}
                size="xl" />
        </Link>
    )
}

const EventNav = () => {

    return (
      <div className='event_nav'>
        <AnimatedNavLink iconIndex={0} />
        <AnimatedNavLink iconIndex={1} />
        <AnimatedNavLink iconIndex={2} />
        <AnimatedNavLink iconIndex={3} />
      </div>
    );
  };
  
  export default EventNav;
  