/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as d3 from 'd3';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faBinoculars, faEllipsis, faLightbulb, faRocket, faStopwatch, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash, faArrowUpFromBracket, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faEye, faMessage, faCommentDots, faSmileWink } from '@fortawesome/free-regular-svg-icons';

import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';

import './LocalLinksBarChart.css'

import config from '../../config';

const mappingStatsURL = config.createApiUrl('events', `/forum/aggregated_stats`, config.URL_TYPES.API)

const iconLookup = {
  inquire: faBinoculars,
  appreciate: faThumbsUp,
  innovate: faLightbulb,
  enhance: faRocket,
};

const termLookup = {
  inquire: 'Explore',
  appreciate: 'Value',
  innovate: 'Renew',
  enhance: 'Boost',
}

// IDEA: Refactor this out to another component
const TimeSinceLastUpdated = ({ lastUpdated }) => {
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState('');

  useEffect(() => {
    const calculateTimeDifference = () => {
      const differenceInMilliseconds = Date.now() - new Date(lastUpdated).getTime();
      const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

      if (differenceInMinutes < 1) {
        setTimeSinceLastUpdate('less than a minute ago');
      } else if (differenceInMinutes < 60) {
        setTimeSinceLastUpdate(`${differenceInMinutes} minute${differenceInMinutes !== 1 ? 's' : ''} ago`);
      } else {
        setTimeSinceLastUpdate('over an hour ago');
      }
    };

    // Calculate the initial time difference
    calculateTimeDifference();

    // Set up a timer to update the time difference every minute
    const timerId = setInterval(calculateTimeDifference, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(timerId);
  }, [lastUpdated]);

  return (
    <h6 className='last_updated_timer'>
      <FontAwesomeIcon icon={faStopwatch} /> Updated {timeSinceLastUpdate}
    </h6>
  );
};

const LocalLinksBarChart = ({ event_id }) => {
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const d3Container = useRef(null);

  const [vibeTable, setVibeTable] = useState(null)

  const handleBarClick = (vibe) => {
    setVibeTable(vibe);
  };

  const fetchMappingStats = () => {
    setIsLoading(true);
    axios.get(`${mappingStatsURL}`, {
      params: { event_id: event_id } // Include event_id in the request if available
    })
    .then(response => {
      setStats(response.data);
      setIsLoading(false);
    })
    .catch(error => {
      console.error("Error fetching mappings stats:", error);
      setErrorMessage("Failed to load data");
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchMappingStats();
  }, [mappingStatsURL, event_id]);

  useEffect(() => {
    if (stats && stats.total_vibes > 0 && d3Container.current) {
      const data = processData(stats);
      renderBarChart(data, d3Container.current, handleBarClick); // Pass the callback here
    }
  }, [stats, vibeTable]);

  useEffect(() => {
    // Cleanup function to run when component unmounts
    return () => {
      if (d3Container.current) {
        d3.select(d3Container.current).selectAll("*").remove();
      }
    };
  }, []);

  const processData = (data) => {
    // Transform data into a suitable format for D3 if necessary
    return data;
  };

  const renderBarChart = (data, container, onClickCallback) => {
    // Use D3 to render the bar chart inside the container
    // Clear previous contents
    d3.select(container).selectAll("*").remove();

    // Explicitly define the order of categories for the x-axis
    const orderedCategories = ['appreciate', 'inquire', 'innovate', 'enhance'];
    
    // Prepare the dataset, ensuring it follows the explicit order defined above
    const dataset = orderedCategories.map(vibe => {
      // Safely access data[vibe], defaulting to an empty object if undefined
      const vibeData = data[vibe] || {};
      const reasons = Object.keys(vibeData).map(reason => ({
        reason,
        count: vibeData[reason]
      }));

      const totalCount = reasons.reduce((sum, current) => sum + current.count, 0);

      return {
        vibe,
        reasons,
        totalCount
      };
    });

    // Set dimensions and margins for the graph using ratios
    const marginRatio = { top: 0.1, right: 0.2, bottom: 0.15, left: 0.1 };
    const widthRatio = 1 - marginRatio.left - marginRatio.right; // Full width minus left and right margins
    const heightRatio = 1 - marginRatio.top - marginRatio.bottom; // Double height minus top and bottom margins

    // These will be used to calculate the actual pixel reasons based on the container's size
    const containerWidth = d3Container.current.clientWidth; // Width of the container
    const containerHeight = d3Container.current.clientHeight; // Height of the container, you might want to define this or get from an element

    const margin = {
      top: containerHeight * marginRatio.top,
      right: containerWidth * marginRatio.right,
      bottom: containerHeight * marginRatio.bottom,
      left: containerWidth * marginRatio.left
    };

    const width = containerWidth * widthRatio - margin.left - margin.right;
    const height = containerHeight * heightRatio - margin.top - margin.bottom;

    // Append the svg object to the div called 'chart'
    // Set the dimensions of the canvas / graph
    const svg = d3.select(container)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `${-margin.left} ${-margin.top} ${width + margin.right} ${height + margin.top + margin.bottom}`)

    // X axis: scale and draw
    const x = d3.scaleBand()
      .rangeRound([0, width])
      .paddingOuter(0.2)
      .paddingInner(0.2)
      .domain(orderedCategories);

    const yMax = d3.max(dataset, d => d.totalCount);

    const y = d3.scaleLinear()
      .domain([0, yMax])
      .nice()
      .range([height, 0]);

    svg.selectAll(".vibe")
      .data(dataset)
      .enter().append("rect")
        .attr("x", d => x(d.vibe))
        .attr("y", d => y(d.totalCount))
        .attr("ry", d => "0.25rem")
        .attr("ry", d => "0.25rem")
        .attr("width", x.bandwidth())
        .attr("height", d => height - y(d.totalCount))
        .attr("fill", "var(--swatch-04)")
      .on("click", function(event, d) {
          onClickCallback(d); // Use the passed callback function
      });

    // Add the X Axis
    svg.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(0," + height + ")")
      .call(
        d3.axisBottom(x)
      )
      .selectAll(".tick")
      .each(function(d) {
        const tick = d3.select(this);
        const fontAwesomeDict = iconLookup[d];
        if (fontAwesomeDict) {
          // Clear existing tick text
          tick.select("text").remove();
          tick.select("line").remove();

          // Append SVG path for the Font Awesome icon
          const iconData = fontAwesomeDict.icon;
          const svg = tick.append("svg")
              .attr("data-prefix", fontAwesomeDict.prefix)
              .attr("viewBox", `0 0 ${iconData[0]} ${iconData[1]}`)
              .attr("fill", "red")
              .attr("width", "0.75rem") // Set the SVG width
              .attr("height", "0.75rem") // Set the SVG height
    
              .attr("x", -6) // Shift the svg to the left by 6 pixels
              .attr("y", 3) // Optionally adjust the y position if needed
    
          // Append the path for the icon on top of the circle
          svg.append("path")
              .attr("d", iconData[4]) // Use the SVG path data
              .attr("fill", "var(--swatch-04)"); // Set the path color
        }
      });

    const getYAxisValues = () => {
      return [0, Math.round(yMax/2), Math.round(yMax/4), yMax]
    }
  
    // Add the Y Axis
    svg.append("g")
      .attr("class", "axis")
      .call(
        d3.axisLeft(y)
          .tickValues(getYAxisValues())
          .tickFormat(d3.format('.0f'))
      );
  };

  if (isLoading) return <Loading />;
  if (errorMessage) return <ErrorMessage errorMessage={errorMessage} />;

  return (
    <div>
      <div className="chart-container">
        {!vibeTable &&
          <svg className="d3_bar_chart" ref={d3Container} />
        }
        {vibeTable && (
          <div className='vibe_table'>
            <div className='vibe_table_header'>
              <div className='forum_info_group'>
                <div className='forum_vibe_label'>
                  <FontAwesomeIcon
                    icon={iconLookup[vibeTable.vibe]}
                  />
                </div>
                <div className='forum_reason_label'>
                  {`${vibeTable.totalCount} Total ${termLookup[vibeTable.vibe]} Vibes`}
                </div>
              </div>
              
              <h6 className='chart-container-back' onClick={() => handleBarClick(null)}>
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                />
                Back
              </h6>
            </div>
              
            <div className='vibe_reason_group'>
              {vibeTable.reasons.map((items, index) => (
                <div key={index} className='vibe_reason'>
                  <span className='number'>{items.count}</span>
                  <span>{items.reason}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {stats?.last_updated && <TimeSinceLastUpdated lastUpdated={stats.last_updated} />}
    </div>
  );
};

export default LocalLinksBarChart;
