/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.
*/
import React, { useEffect, useState, useRef } from 'react';

import './PanImage.css'

const lookup = [
    "simpler-pan-image_one 150s linear infinite",
    "simpler-pan-image_two 100s linear infinite",
    "simpler-pan-image_three 50s linear infinite",
]

const PanImage = ({ height, width, img, panChoice }) => {
    const screenRef = useRef(null);
    const [dynamicHeight, setDynamicHeight] = useState(height);

    useEffect(() => {
        if (!height && screenRef.current) {
            const parentWidth = screenRef.current.parentElement.offsetWidth;
            setDynamicHeight(parentWidth);
        }
    }, [height]);

    return (
        <div ref={screenRef} className="screen" style={{ height: dynamicHeight, width: width }}>
            <div style={{ backgroundImage: `url(${img})`, animation: `${lookup[panChoice]}` }} className="photomation_image screen-image"></div>
        </div>
    );
};

export default PanImage;
