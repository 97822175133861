/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';

import './LineItem.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

function addCommas(number) {
    // Ensure that at least "0" is returned for empty or zero values
    if (!number || number === "0") {
      return '$0';
    }
    return '$' + parseInt(number, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function removeNonNumericAndDollar(string) {
    return string.replace(/[^0-9]/g, '');
}
  
// Define LineItem outside EditPage to avoid re-creation on each render
const LineItem = ({
  section,
  item,
  index,
  onAmountChange,
  onLabelCaptionChange,
  onRemoveItem,
  isEditing
}) => {
    const [editingValue, setEditingValue] = useState(item.amount.toString());
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (isFocused) {
          // Format the value only when the input is focused
          const valueWithoutLeadingZeros = parseInt(removeNonNumericAndDollar(item.amount.toString()), 10).toString();
          setEditingValue(valueWithoutLeadingZeros || "");
        }
    }, [isFocused, item.amount]);
      

    const handleAmountFocus = (e) => {
        setIsFocused(true);
        // We can remove the parsing logic from here if we are handling it in useEffect
    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        const numericValue = removeNonNumericAndDollar(value);
        // Directly setting the state without leading zero removal logic since it's handled on focus and before update
        setEditingValue(numericValue);
    };

    const handleAmountBlur = () => {
        setIsFocused(false);
        const numericValue = removeNonNumericAndDollar(editingValue);
        if (numericValue) {
        // Parse and format the value to ensure removal of leading zeros and proper formatting
        const formattedValue = addCommas(parseInt(numericValue, 10).toString());
        onAmountChange(section, index, numericValue); // Send back the numeric value without leading zeros.
        setEditingValue(formattedValue); // Display formatted value with dollar sign.
        } else {
        // If the field is effectively empty, set the value to '0', format, and prepend dollar sign.
        onAmountChange(section, index, '0');
        setEditingValue(addCommas('0'));
        }
    };

    return (
        <div className="input-group">
            <div className='line_item input-container'>
                {isEditing ? (
                    <>
                        <div className='edit_area'>
                            <div className="remove" 
                                onClick={() => onRemoveItem(section, index)}>
                                <FontAwesomeIcon icon={faCircleXmark} size={'lg'} />        
                            </div>
                            <div className='line_item-input-edit'>
                                <input
                                    className='label'
                                    type="text"
                                    value={item.label}
                                    onChange={(e) => onLabelCaptionChange(section, index, 'label', e.target.value)}
                                    placeholder="Label"
                                    required
                                />
                                <input
                                    className='p'
                                    type="text"
                                    value={item.caption}
                                    onChange={(e) => onLabelCaptionChange(section, index, 'caption', e.target.value)}
                                    placeholder="Caption"
                                    required
                                />
                            </div>
                        </div>
                        <div className='line_item-text'>
                            <div className='amount'>{addCommas(item.amount)}</div>
                        </div>
                    </>
                ) : (
                    <div className='line_item-text'>
                        <label>{item.label}</label>
                        <p>{item.caption}</p>
                    </div>
                )}
                {!isEditing &&
                    <input
                        type="text"
                        value={editingValue}
                        onFocus={handleAmountFocus}
                        onChange={handleAmountChange}
                        onBlur={handleAmountBlur}
                        placeholder="Amount"
                        required
                    />
                }
            </div>
        </div> 
    );
};

export default LineItem;
