/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import './Toggle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartCrack, faHeart } from '@fortawesome/free-solid-svg-icons';
import Switch from '@mui/material/Switch';

const Toggle = ({showAll, setShowAll}) => {
  const handleToggle = () => {
    setShowAll(!showAll)
  };

  return (
    <div className='toggle-net-container'>
      <div className='toggle-net'>
        <div className='toggle-icon'>
          <FontAwesomeIcon
            icon={showAll ? faHeartCrack : faHeart}
            size={"lg"} />
        </div>
        <div className='toggle-text'>
          {showAll ? 'Included' : 'Only'}
        </div>
        <div className='toggle-switch'>
          <Switch checked={showAll} onChange={handleToggle} className="custom-switch"/>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
