/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function UnixConverter() {
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs()); // Initial value with Day.js object
  const [localDate, setLocalDate] = useState(dayjs().format('DD MMM YYYY'));
  const [localTime, setLocalTime] = useState(dayjs().format('HH:mm:ss'));
  const [unixTimestamp, setUnixTimestamp] = useState(selectedDateTime.valueOf());

  const handleConvertAndCopy = () => {
    const now = dayjs();
    const timestamp = selectedDateTime.valueOf(); // Get Unix timestamp in milliseconds
    
    // Update the state
    setUnixTimestamp(timestamp);
    setLocalDate(now.format('DD MMM YYYY'));
    setLocalTime(now.format('HH:mm:ss'));
    
    // Copy to clipboard
    navigator.clipboard.writeText(timestamp.toString())
      .then(() => {
        // Do nothing
      })
      .catch((err) => {
        alert('Failed to copy the timestamp. Please try again.');
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h6">local timezone (UTC-04:00)</Typography>
          <Typography variant="h4">Eastern Daylight</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Convert local YYYY / MM / DD and HH : MM : SS</Typography>
          <DateTimePicker
            value={selectedDateTime}
            onChange={(newValue) => {
              if (dayjs.isDayjs(newValue)) {
                setSelectedDateTime(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item>
          <Button color="success" variant="contained" onClick={handleConvertAndCopy}>
            Convert & Copy to Clipboard
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h6">to milliseconds since epoch:</Typography>
          <Typography variant="h4">{unixTimestamp}</Typography>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default UnixConverter;
