/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import './DataControl.css';

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const DataControl = () => {
  const openCookieModal = () => {
    window.displayPreferenceModal();
    return false;
  };

  return (
    <>
      <div className='data-control'>
        <h1>Data Control</h1>
        <div className="card">
          <h6>
            For more support on removing specific data, please reach out to us at <a href="mailto:support@revibe.life">support@revibe.life</a>.
          </h6>
          <h6>
            Or, change your consent preferences
          </h6>
          <div className='settings-buttons'>
            <a className='button' href="#" onClick={openCookieModal} id="termly-consent-preferences">
              Preferences
            </a>
          </div>
        </div>
      </div>
    <div className='event_nav' style={{zIndex: 0}}>
        <Link to={`/resources/`}>
          <div className="fa-button">
              <FontAwesomeIcon
                  icon={faArrowLeft}
                  size={"xl"}
              />
          </div>
        </Link>
    </div>
    </>
  );
};

export default DataControl;
