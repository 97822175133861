/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate} from 'react-router-dom';

import config from '../../config';
import Event from '../../components/Event';
import EventNav from '../../components/EventNav';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';

const api = config.createApiUrl('events', `/event/`, config.URL_TYPES.API);

const EventPage = () => {
  
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [event, setEvent] = useState({});

  let { eventID } = useParams();

  useEffect(() => {
    const api = config.createApiUrl('events', `/event/${eventID}`, config.URL_TYPES.API);
    
    try {
      axios.get(`${api}`)
        .then(response => {
        setEvent(response.data);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err)
        if (err.response?.status == 404) {
          navigate("/");
        } else {
          setErrorMessage('There was an error. Try again in a few minutes.');
        }
        setIsLoading(false);
      });
    } catch {
      setErrorMessage('There was an error. Try again in a few minutes.');
      setIsLoading(false);
    }
  }, []);

  if (!isLoading && errorMessage) {
    return (
      <>
        <ErrorMessage errorMessage={errorMessage} />
        <EventNav />
      </>
    )    
  }


  if (isLoading) {
    return (
      <div className='events_wrapper'>
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <div>
        <Event key={event.id} {...event} isPage={true} />
      </div>
      <EventNav />
    </div>
  );
};

export default EventPage;
