/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, {useState} from 'react';
import { Link } from 'react-router-dom'

import EventNav from '../../components/EventNav';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMoneyBill1Wave, 
  faComment, 
  faQuestion, 
  faGavel, 
  faDatabase,
  faPeopleGroup,
  faUserCircle,
  faInfinity,
  faBookBookmark,
  faCircleXmark,
  faHeart,
  faGrinBeam,
  faToolbox,
  faListOl,
  faBook,
  faScroll
} from '@fortawesome/free-solid-svg-icons'; // Import relevant icons

import './Resources.css'

import config from '../../config';

const directoryUrl = config.createApiUrl('directory', `/`, config.URL_TYPES.BASE);
const identityUrl = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);
const forumUrl = config.createApiUrl('forum', `/`, config.URL_TYPES.BASE);

const userManualURL = "https://revibe-life-assets.s3.amazonaws.com/Revibe_Life_v1.2.0_User_Manual.pdf"

const ResourcesOption = ({ icon, title, subtitle, isInActive, to }) => (
  <Link to={to}>
    <div className={`resources_option ${isInActive ? 'isInActive' : ''}`}>
      <FontAwesomeIcon icon={icon} size="xl" />
      <div className="text_container">
        <h5>{title}</h5>
        <h6>{subtitle}</h6>
      </div>
    </div>
  </Link>
);

const Resources = () => {
  
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
  <>
    <div className="resources_container">
      <div className='resources'>
        <div id="resources_heading">
          <h3>
            <FontAwesomeIcon icon={faToolbox} />
            Resources
          </h3>
          <h6>Nifty microapps, useful information, and other important links</h6>
        </div>
        <div className='resources_option_list'>
          <h6>Microapps</h6>
          <ResourcesOption
            to={'/tutorial'}
            isInActive={false}
            icon={faHeart}
            title="Tutorial"
            subtitle="Learn about Revibe" />
          <ResourcesOption
            to={'/budget'}
            isInActive={false}
            icon={faMoneyBill1Wave}
            title="Simple Budget"
            subtitle="Estimate your budget" />
          <h6>Useful Information</h6>
          <ResourcesOption
            to={'/mission'}
            isInActive={false}
            icon={faInfinity}
            title="Revibe Mission"
            subtitle="Explore our mission" />
          <ResourcesOption
            to={'/stats'}
            isInActive={false}
            icon={faListOl}
            title="Revibe Statistics"
            subtitle="Last week's vibes" />
          <ResourcesOption
            to={'/goodvibes'}
            isInActive={false}
            icon={faGrinBeam}
            title="Good Vibes"
            subtitle="Read our introduction" />
          <ResourcesOption
            to={'/rules'}
            isInActive={false}
            icon={faBookBookmark}
            title="Rules"
            subtitle="Follow our guidelines" />
          <ResourcesOption
            to={'/faqs'}
            isInActive={false}
            icon={faQuestion}
            title="FAQs"
            subtitle="Gain clarity" />
          <ResourcesOption
            to={'/policies'}
            isInActive={false}
            icon={faGavel}
            title="Legal Policies"
            subtitle="View legal terms" />
          <ResourcesOption
            to={userManualURL}
            isInActive={false}
            icon={faBook}
            title="User Manual"
            subtitle="Understand Revibe" />
          <ResourcesOption
            to={'/releases'}
            isInActive={false}
            icon={faScroll}
            title="Release Notes"
            subtitle="Reflect on Revibe History" />
          <h6>Important Links</h6>
          <ResourcesOption
            to={identityUrl}
            isInActive={false}
            icon={faUserCircle}
            title="Profile"
            subtitle="Manage account settings" />
          <ResourcesOption
            to={`${forumUrl}forum/new`}
            isInActive={false}
            icon={faComment}
            title="Feedback"
            subtitle="Share your thoughts" />
          <ResourcesOption
            to={`${directoryUrl}groups`}
            isInActive={false}
            icon={faPeopleGroup}
            title="View Groups"
            subtitle="Customize groups" />
          <ResourcesOption
            to={'/data-control'}
            isInActive={false}
            icon={faDatabase}
            title="Data Control"
            subtitle="Update data preferences" />
        </div>
        
      </div>
      <h6><b>v1.2.0</b></h6>
    
      <EventNav />
    </div>
  </>
  );
}

export default Resources;
