/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.
*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import SearchNav from '../SearchNav';
import Loading from '../../components/Loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableList, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';

import './EventList.css';

import config from '../../config';

import QueryEventCalendar from '../../components/QueryEventCalendar';
import EventTable from '../../components/EventTable';

import dayjs from 'dayjs';
import ErrorMessage from '../ErrorMessage';

const EventList = ({ id }) => {
    const currentDate = dayjs();
    const startWeek = currentDate.startOf('week');
    const endWeek = currentDate.endOf('week');

    const [showWeek, setShowWeek] = useState(false);
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [eventServiceDown, setEventServiceDown] = useState(false);

    const api = config.createApiUrl('events', `/query/`, config.URL_TYPES.API);
    
    // Search Params
    const [startDate, setStartDate] = useState(startWeek);
    const [endDate, setEndDate] = useState(endWeek);
    const [searchString, setSearchString] = useState('');
    const [locationFilter, setLocationFilter] = useState('');

    const handleStartDateChange = (newValue) => {
      setStartDate(newValue);
      setIsLoading(true);
    };

    const handleEndDateChange = (newValue) => {
      setEndDate(newValue);
      setIsLoading(true);
    };

    const loadEventsForDateRange = (calendarDateInfo) => {
      let params;

      if (calendarDateInfo) {
        params = new URLSearchParams({
          page: page,
          search: searchString,
          location_filter: locationFilter,
          start_date: new Date(calendarDateInfo.startStr).getTime(),
          end_date: new Date(calendarDateInfo.endStr).getTime()
        }).toString();
      } else if (startDate && endDate) {
        params = new URLSearchParams({
          page: page,
          search: searchString,
          location_filter: locationFilter,
          start_date: new Date(startDate).getTime(),
          end_date: new Date(endDate).getTime()
        }).toString();
      }

      axios.get(`${api}?${params}`)
      .then(response => {
        setEvents(response.data.events);
        setPaginationData(response.data.pagination);
        setIsLoading(false);
      })
      .catch(err => {
        if (!err.response?.data) {
          setEventServiceDown(true);
        }
        console.error(err);
        setIsLoading(false);
      });
    };
  
    useEffect(() => {
      let debounceTimer;
  
      if (isFirstRender) {
        setIsLoading(true);
        loadEventsForDateRange();
        setIsFirstRender(false);
      } else {
        debounceTimer = setTimeout(() => {
          setIsLoading(true);
          loadEventsForDateRange();
        }, 2000);
      }
  
      return () => debounceTimer && clearTimeout(debounceTimer);
    }, [page, startDate, endDate, searchString, locationFilter]);

    const handleSearch = event => {
      setPage(1);
      setSearchString(event.target.value);
    };

    const handleLocationFilter = event => {
      setPage(1);
      setLocationFilter(event.target.value);
    };

    const toggleView = (isCalendar) => {
      if (isCalendar) {
        setShowWeek(true);
      } else {
        setShowWeek(false);
        handleStartDateChange(dayjs(startWeek));
        handleEndDateChange(dayjs(endWeek));
      }
    };

    if (isLoading) {
      return (
        <div style={{
            height: "24rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Loading />
        </div>
      )
    }

    return (
      <div>
        <div className='event-switch-container'>
          <div className='event-switch'>
            <div className={showWeek ? 'button' : 'button inactive'}
                onClick={() => toggleView(true)}>
              <FontAwesomeIcon icon={faCalendarWeek} />
              Calendar
            </div>
            <div className={showWeek ? 'button inactive' : 'button'}
                onClick={() => toggleView(false)}>
              <FontAwesomeIcon icon={faTableList} />
              Table
            </div>
          </div>
        </div>
        {showWeek && events &&
          <QueryEventCalendar
            events={events}
            onDateChange={loadEventsForDateRange}
            isFirstRender={isFirstRender}
            eventServiceDown={eventServiceDown}
          />
        }
        {!showWeek && events &&
          <div id='event-table'>
            <ErrorMessage errorMessage={"Explore up to 200 events by title, description, or location."} />
            
            <div className="input-container">
              <label>
                Search
              </label>
              <input style={{padding: "0.6rem"}} type="text" value={searchString} onChange={handleSearch} placeholder="Find your vibe" />
            </div>

            <div className="input-container">
              <label>
                Filter by Area
              </label>
              <input style={{padding: "0.6rem"}} type="text" value={locationFilter} onChange={handleLocationFilter} placeholder="Where's the vibe happening?" />
            </div>

            <EventTable 
              events={events}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              eventServiceDown={eventServiceDown}
            />
          </div>
        }
      </div>
    );
};

export default EventList;
