/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import EventList from '../../components/EventList';

import './QueryEngine.css'

function QueryEngine({ }) {
    return (
        <div className='org-event_list'>
            <div id="query_engine-event_list">
                <EventList />
            </div>
            <div className='search_nav'>
              <Link to="/search" className='fa-button search_nav-back'>
                  <FontAwesomeIcon
                      icon={faArrowLeft}
                      size="xl" />
              </Link>
            </div>
        </div>
    );
}

export default QueryEngine;
