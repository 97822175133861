/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './BlurLoad.css'
import defaultImage from './images/default01.png';

const BlurLoad = ({ height, width, src  }) => {
  
  return (
    <div>
    {src ? (
      <img src={src} width={width} height={height} />
    ) : (
      <img
        src={defaultImage}
        width={width}
        height={height}
        style={{ filter: 'blur(20px)' }}      />
    )}
  </div>
  )}
export default BlurLoad;