/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';

import './PersonalCalendar.css';

import config from '../../config';

import EventCalendar from '../../components/EventCalendar'
import EventNav from '../../components/EventNav';

const PersonalCalendar = () => {
    // IDEA: The 'id' is the user id, and we need to read this from the server
    // using the JWT token. After a user is authenticated
    const [events, setEvents] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(null);
    const [needAuth, setNeedAuth] = useState(false);

    const [showAll, setShowAll] = useState(false)

    const [globalDateInfo, setGlobalDateInfo] = useState(null)
    
    const [api, setApi] = useState(null); // Use state to manage the 'api' variable

    const userIDAPI = config.createApiUrl('identity', `/user/whoami`, config.URL_TYPES.API);
    const loginRoute = config.createApiUrl('events', `/auth`, config.URL_TYPES.BASE);
    
    useEffect(() => {
      const fetchUserIdAndSetURL = async () => {
          try {
              const response = await axios.get(userIDAPI);
              const id = response.data.logged_in_as;
              // Then, if the id is valid, we can use it to construct the apiURL, and then set it for later
              const apiUrl = config.createApiUrl('events', `/interaction/user/${id}/events`, config.URL_TYPES.API);
              setApi(apiUrl);
            } catch (error) {
              if (error.response?.status === 401) {
                setNeedAuth(true)
                setServerError('Please log in to continue')
              } else {
                setServerError('There was a server error')
              }
            }
          setIsLoading(false);
      };
        
      fetchUserIdAndSetURL();
  }, [page, search]);

  useEffect(() => {
    // 'globalDateInfo' should always be the last dateInfo the the
    // EventCalendar component set
    if(globalDateInfo) {
      loadEventsForDateRange(globalDateInfo);
    }
  }, [showAll]);
  
  // Updated Calendar Helper function
  // This is the main function that reloads the events
  const loadEventsForDateRange = (dateInfo) => {
      const startDate = new Date(dateInfo.startStr).getTime();
      const endDate = new Date(dateInfo.endStr).getTime();
      setGlobalDateInfo(dateInfo)
      setIsLoading(true);
  
      axios.get(api, {
        params: {
          page: page,
          search: search,
          start_date: startDate,
          end_date: endDate,
          show_all: showAll,
        }
      })
      .then(response => {
          setEvents(response.data.events);
          setPaginationData(response.data.pagination);
      })
      .catch(err => {
          console.error(err);
      });
  
      setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className='events_wrapper'>
        <Loading />
        <EventNav />
      </div>
    )
  }
  
  if (serverError) {
    return (
      <div>
        {needAuth &&
          <Loading />
        }
        {!needAuth &&
        <>
          <ErrorMessage errorMessage={serverError} />
          <h5 style={{textAlign: "center", margin: 0}}>
            Try again in a few minutes
          </h5>
        </>
        }
        <EventNav />
      </div>
    )
  }

  return (
    <div>
      {events &&
        <EventCalendar
          events={events} 
          onDateChange={loadEventsForDateRange}
          setShowAll={setShowAll}
          showAll={showAll}
        />
      }
      
      <EventNav />
    </div>
  );
};

export default PersonalCalendar;