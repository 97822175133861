/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect, useRef } from 'react';

function VideoPlayer({ videoLoaded, src, onReadyStateChange, width, height }) {
  const videoRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const checkLoad = () => {
      onReadyStateChange(video.readyState);
      if (video.readyState === 4) {
        clearInterval(intervalRef.current);  // Clear the interval once fully loaded
      }
    };

    video.addEventListener('loadedmetadata', checkLoad);
    // Set up an interval to periodically check readyState
    intervalRef.current = setInterval(checkLoad, 500);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      video.removeEventListener('loadedmetadata', checkLoad);
    };
  }, [src, onReadyStateChange]);  // Depend on onReadyStateChange and src to reinitialize the effect when they change

  return (
    <video style={{display: videoLoaded ? '' : 'none'}} ref={videoRef} src={src} width={width} height={height} autoPlay muted loop>
      Your browser does not support the video tag.
    </video>
  );
}

export default VideoPlayer