/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
// React component
import React from 'react';
import './ChipScroll.css'; // make sure to create this CSS file

const Chip = ({vibe, setRelatedReason, selectedReason}) => {
  return (
    <div
      className={`chip ${(selectedReason === vibe) ? 'selected' : ''}`}
      onClick={() => setRelatedReason(vibe)}
    >
        {vibe}
    </div>
  )
}

const Chips = ({goodVibes, setRelatedReason, selectedReason}) => {
  let output = [];
  for (const vibe of goodVibes) {
    output.push(
      <Chip 
        key={vibe.substring(0, 6)}
        setRelatedReason={setRelatedReason} 
        selectedReason={selectedReason} 
        vibe={vibe}
      />
    )
  }
  return output
}


const ChipScroll = ({goodVibes, setRelatedReason, selectedReason}) => {
  return (
    <div className="chip-container">
      <div className="chip-scroll">
        <Chips 
          goodVibes={goodVibes} 
          setRelatedReason={setRelatedReason}
          selectedReason={selectedReason}
        />
      </div>
    </div>
  );
}

export default ChipScroll;
