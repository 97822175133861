/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import './LegalPolicies.css';

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import logoBlock from '../../logo-emblem.png';

const LegalItem = ({ href, icon, title, description }) => (
  <div className="legal-item card">
    <a href={href} target="_blank" rel="noopener noreferrer" className="legal-link">
      <div className="legal-content">
        <h5>{title}</h5>
        <h6>{description}</h6>
      </div>
    </a>
  </div>
);

const LegalPolicies = () => (
  <>
    <div className="legal-container">
      <h1 className="legal-title">Legal</h1>
      <div className="legal-list">
        <div>
          <LegalItem
            href="/terms"
            title="Terms of Service"
            description="Terms when you use the products and services." />
          <LegalItem
            href="/disclaimer"
            title="Disclaimer"
            description="This disclaimer outlines the limitations of our responsibilities regarding your use of our website, information, services, and more." />
        </div>
      </div>
      <div className="legal-header">
        <h1 className="legal-title">Policies</h1>
      </div>
      <div className="legal-list">
        <div>  
          <LegalItem
            href="/privacy"
            title="Privacy Policy"
            description="Practices with respect to personal information we collect." />
          <LegalItem
            href="/cookies"
            title="Cookie Policy"
            description="Information regarding our use of cookies." />
          <LegalItem
            href="/usage"
            title="Acceptable Use Policy"
            description="This policy defines the acceptable ways in which our services and products can be used, ensuring that users adhere to legal and ethical standards." />
        </div>
      </div>
    </div>
    <div className='event_nav' style={{zIndex: 0}}>
        <Link to={`/resources/`}>
          <div className="fa-button">
              <FontAwesomeIcon
                  icon={faArrowLeft}
                  size={"xl"}
              />
          </div>
        </Link>
    </div>
  </>
);

export default LegalPolicies;
