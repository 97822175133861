/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
let config;
let environment;

if (process.env.NODE_ENV === 'production') {
    config = require('./config.prod');
    environment = 'production';
} else {
    config = require('./config.dev');
    environment = 'development';
}

// Define constants for each type of URL
const URL_TYPES = {
    API: 0,
    BASE: 1,
    // EXTERNAL: 2, // Not implemented yet
};

function createApiUrl(serviceName, endpoint, urlType) {
    let baseUrl;

    switch (urlType) {
        case URL_TYPES.API:
            if (environment === 'production') {
                const configKey = `${serviceName.toUpperCase()}_BASE_URL`;
                baseUrl = config[configKey];
                baseUrl += '/api';
            } else {
                const configKey = `${serviceName.toUpperCase()}_API_URL`;
                baseUrl = config[configKey];
            }
            break;

        case URL_TYPES.BASE:
            const configKey = `${serviceName.toUpperCase()}_BASE_URL`;
            baseUrl = config[configKey];
            break;

        // case URL_TYPES.EXTERNAL:
        //     // Logic for handling external URLs
        //     break;

        default:
            throw new Error(`Invalid URL type: ${urlType}`);
    }

    return `${baseUrl}${endpoint}`;
}


config.createApiUrl = createApiUrl;
config.URL_TYPES = URL_TYPES;

module.exports = config;
