/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Event from '../../components/Event';

import { Link } from 'react-router-dom'

import SearchNav from '../../components/SearchNav';
import Loading from '../../components/Loading';

import './Stats.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

import config from '../../config';
import ErrorMessage from '../../components/ErrorMessage';

import PhotoMation from '../../components//PhotoMation';

const api = config.createApiUrl('events', `/event_stats`, config.URL_TYPES.API);

const formatNumber = (number) => {
  if (number < 1000) return number; // return the number as is if less than 1000
  if (number >= 1000 && number < 1000000) return `${(number / 1000).toFixed(1)}K`; // convert to K for thousands
  if (number >= 1000000 && number < 1000000000) return `${(number / 1000000).toFixed(1)}M`; // convert to M for millions
  if (number >= 1000000000) return `${(number / 1000000000).toFixed(1)}B`; // convert to B for billions
}

const UsageStats = ({ usage_count }) => {
  return (
    <div className='stat_count'>
      <b>
        {`${formatNumber(usage_count)} Event${usage_count !== 1 ? 's' : ''}`}
      </b>
    </div>
  );
}


const ImageItem = ({
  usage_count,
  filename,
  index
}) => {
  console.log(filename)
  if (filename) {
    const assetIdentifier = filename.split('.')[0];
    return (
      <div>
        <div className='stat_picture'>
          <div className='event_image'>
            <PhotoMation 
              panChoice={index} 
              assetIdentifier={assetIdentifier} 
              />
          </div>
        </div>
        <UsageStats usage_count={usage_count} />
      </div>
    )
  }
}


const StatItem = ({
  index,
  average_duration,  // duration in milliseconds
  event_count,
  organization_id,
}) => {

  const viewOrg = () => {
    const directoryURL = config.createApiUrl('directory', `/public/groups/${organization_id}`, config.URL_TYPES.BASE);
    
    window.location = directoryURL;
  }

  const msInHour = 1000 * 60 * 60;
  const msInDay = msInHour * 24;

  const durationInDays = Math.round(average_duration / msInDay);
  const durationInHours = Math.round(average_duration / msInHour);

  // Determine the correct display based on the duration
  const durationDisplay = average_duration >= msInDay
    ? `${durationInDays} day${durationInDays !== 1 ? 's' : ''}`
    : `${durationInHours} hour${durationInHours !== 1 ? 's' : ''}`;

  return (
    <div className='stat_item forum_info_item'>
      <div className='forum_info_group'>
          <div className='forum_vibe_label'>{index + 1}</div>
          <div className='forum_reason_label'>
            <b>
              {`${event_count} Event${event_count !== 1 ? 's' : ''}`}
            </b>
            <br />
            <i>{durationDisplay} <span>per event (avg time)</span></i>
          </div>
      </div>  
      <div className='link_to_forum' onClick={viewOrg}>
        <FontAwesomeIcon icon={faPeopleGroup} />
      </div>
    </div>
  );
};

const Stats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [stats, setStats] = useState([]);
  const [topPictures, setTopPictures] = useState([]);
  
  useEffect(() => {
    setIsLoading(true);
  
    Promise.all([
      axios.get(`${api}/org:leaderboard`),
      axios.get(`${api}/top_3_pictures`)
    ]).then(responses => {
      // Setting stats from the first API response
      setStats(responses[0].data);
      // Setting top pictures from the second API response
      setTopPictures(responses[1].data);
      setIsLoading(false);
    }).catch(error => {
      console.error(error);
      setErrorMessage("There was a server error")
      setIsLoading(false);
    });
  
  }, []);
  
  if (errorMessage) {
      return (
        <div >
          <ErrorMessage errorMessage={errorMessage} />
          <div className='event_nav' style={{zIndex: 0}}>
            <Link to={`/resources/`}>
              <div className="fa-button">
                  <FontAwesomeIcon
                      icon={faArrowLeft}
                      size={"xl"}
                  />
              </div>
            </Link>
          </div>
        </div>
      )
  }
  
  if (isLoading) {
      return (
        <div className='events_wrapper'>
          <Loading />
          <div className='event_nav' style={{zIndex: 0}}>
            <Link to={`/resources/`}>
              <div className="fa-button">
                  <FontAwesomeIcon
                      icon={faArrowLeft}
                      size={"xl"}
                  />
              </div>
            </Link>
          </div>
        </div>
      )
  }
  
  return (
    <div>
      <div className='stats'>
          <h1>Revibe Stats</h1>
          {topPictures.length > 0 && (
            <>
              <h6>These are the most popular images</h6>
              <div className='top_images'>
              {
                topPictures.map((image, index) => {
                  return <ImageItem key={index} index={index} {...image} />
                })
              }
              </div>
            </>
          )}
          {stats.length > 0 ? (
            <>
              <h5>Last week's vibes</h5>
              <h6>From hour-long sessions to multi-day gatherings, we ranked groups based on who created the most events. {stats.length === 100 ? '(Only the first 100 groups are available)' : ''}</h6>
            {
              
              stats.map((row, index) => {
                return <StatItem key={index} index={index} {...row} />
              })
            }
            </>
          ) : (
            <div className="no-events-message">
              <h6>No vibes found in the past week to rank</h6>
            </div>
          )}
      </div>
      <div className='event_nav' style={{zIndex: 0}}>
          <Link to={`/resources/`}>
            <div className="fa-button">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    size={"xl"}
                />
            </div>
          </Link>
      </div>
    </div>
  );
}

export default Stats;
