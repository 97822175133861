/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import './OurGoodVibes.css';

import { useNavigate } from 'react-router-dom'

import logoBlock from '../../logo-emblem.png'; // Adjust this path to your actual image path
import logoBlockDark from '../../logo-emblem-metal.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDatabase, faHandshake, faShieldAlt, faSmile, faHeart, faUserPlus, faBan, faBiohazard, faUserSecret, faSackDollar, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

// IDEA: Refactor to a common component
const LegalLink = ({ href, text }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="legal-link">
    {text}
  </a>
);

const OurGoodVibes = ({ setHasAccepted, redirectPath }) => {
  const navigate = useNavigate()

  const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const handleAccept = () => {
    localStorage.setItem("visited_good_vibes", "true"); // Update local storage
    setHasAccepted(true); // Update the parent component's state to reflect acceptance
    navigate(redirectPath || '/search');
  };

  return (
    <div id='good_vibes-container'>
      <div className="logo_wrapper">
        <img className="logo-block" src={isDarkMode ? logoBlockDark : logoBlock} alt="Revibe Logo" />
      </div>
      <div id='good_vibes-main_content' className='card'>
        <h6>When engaging with our platform, please follow all applicable laws. Revibe Life is designed for adults over the age of 18. If you are under the age of 18, please ask for parental guidance</h6>
        
        <h6>Our "Good Vibes" are some general tips that summarize our legal policies. Folllow these guidelines to have a great experience with Revibe</h6>

        <h4>Using Our Services</h4>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faDatabase} size="lg" />Data Integrity</strong>:
          Only collect data as explicitly approved in our terms
        </p>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faHandshake} size="lg" />Be Respectful</strong>:
          Avoid hacking, spamming, or misleading other users
        </p>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faShieldAlt} size="lg" />Secure Your Space</strong>:
          Respect our security features and protect your account and others
        </p>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faSmile} size="lg" />Positive Intent</strong>:
          Use our services for constructive and legal purposes only
        </p>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faSackDollar} size="lg" />Authorized Commercial Use</strong>:
          Only engage in monetization activities as explicitly approved in our terms
        </p>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faScrewdriverWrench} size="lg" />Protect Our Craftsmanship</strong>:
          Reverse engineering our software is not permitted
        </p>

        <h4>Revibe Platform Vibes</h4>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faHeart} size="lg" />Be Respectful</strong>:
          Help us sustain a healthy environment and let us know of any behavior that falls short
        </p>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faUserPlus} size="lg" />Your Contributions</strong>:
          When posting or sharing content, ensure it is lawful, respectful, and genuinely yours
        </p>

        <h4>Hazards to Avoid</h4>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faBan} size="lg" />Forbidden Items</strong>:
          Anything illegal has no place here
        </p>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faBiohazard} size="lg" />Harmful Substances</strong>:
          Steer clear of hazardous materials and anything promoting harm or violence
        </p>
        <p className='good_vibe_item'>
          <strong><FontAwesomeIcon icon={faUserSecret} size="lg" />Respect Privacy</strong>:
          Refrain from posting or selling anyone's private information
        </p>
      </div>
      <div>
        <div id="good_vibes-disclaimer">
          Thank you for reading our "Good Vibes" and please read all of our policies. By continuing, you agree to our <LegalLink href="/terms" text="Terms of Service" /> and acknowledge our <LegalLink href="/privacy"  text="Privacy Policy" />
        </div>
        <div onClick={handleAccept} id="good_vibes_next_button" className='gradient_button button' >
            <FontAwesomeIcon
                icon={faCheck}
                size="lg" />
            <div>
              I agree
            </div>
        </div>
      </div>
    </div>
  );
};

export default OurGoodVibes;
