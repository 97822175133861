/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom'

import TutorialEvent from '../../components/TutorialEvent';
import Rules from '../Rules/Rules';

import SearchNav from '../../components/SearchNav';
import Loading from '../../components/Loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHeart, faHeartCrack, faArrowLeft, faBookOpenReader, faCircleUser, faToolbox, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';

import Modal from 'react-modal';

import config from '../../config';

import './Tutorial.css'
import ErrorMessage from '../../components/ErrorMessage';

const api = config.createApiUrl('events', `/event/`, config.URL_TYPES.API);
const userIDAPI = config.createApiUrl('identity', `/user/whoami`, config.URL_TYPES.API);
const identityURL = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);
const eventURL = config.createApiUrl('events', `/`, config.URL_TYPES.BASE);

const eventNames = [
  "🖥️ Tech Talk",
  "Wonderful 🛠️ Workshop",
  "Family Fun 🎉",
  "Creative Conference 🎨",
  "Gaming 🎮 Gathering",
  "Learning Lab 🔬",
  "Makers 🤖 Meeting",
  "Digital 🗣️ Discussion",
  "Productivity 📊 Panel",
  "Reading 📚 Retreat",
  "🏃 Training Trek",
  "Cool 🎠 Carnival",
  "Health 🧬 Hackathon",
  "Growth Gala 🌱",
  "Leadership 🛋️ Lounge",
  "Quality 🌟 Quest",
  "Research 🤝 Rendezvous",
  "Technology 🚌 Tour",
  "Magnificent 🎩 Meeting",
  "Fun 🎢 Festival",
  "⭐ Starlight Symposium",
  "Startup ⭐ Stars",
  "⭐ Star-studded Seminar",
  "Science ⭐ Stars",
  "⭐ Star Speaker Series",
  "⭐ Star Showcase",
  "Sculpting ⭐ Stars",
  "Singing ⭐ Stars",
  "⭐ Star Skill Share",
  "⭐ Star Scholarship Summit",
];

// Function to generate a random integer between two values (inclusive)
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Function to generate random date and time within specified constraints
function generateRandomTimes() {
  // Set start date to tomorrow
  let startDate = new Date();
  startDate.setDate(startDate.getDate() + 1);
  startDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds

  // Set end date to a week from tomorrow
  let endDateLimit = new Date(startDate);
  endDateLimit.setDate(startDate.getDate() + 7);

  // Generate random start time within the day (8 AM to 2 PM)
  startDate.setHours(getRandomInt(8, 14), 0, 0, 0); // 14 is exclusive

  // Generate a random day within the next week
  let endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + getRandomInt(0, 7)); // 0 for same day, up to 7 days later

  // Generate end time (3 PM to 10 PM)
  endDate.setHours(getRandomInt(15, 22), 0, 0, 0); // 22 is exclusive

  return {
    start_date: startDate,
    end_date: endDate
  };
}

const getRandomNumberWithFixedWidth = (maxNumber) => {
  // Ensure maxNumber is a valid number and greater than 0
  if (maxNumber < 1 || maxNumber > 999) {
      throw new Error("Maximum number must be between 1 and 999 for three-digit formatting");
  }

  // Generate a random number from 1 to maxNumber
  const randomNumber = Math.floor(Math.random() * maxNumber) + 1;

  // Convert the number to a string and pad it with leading zeros to ensure three digits
  const formattedNumber = randomNumber.toString().padStart(3, '0');

  return formattedNumber;
}

const Tutorial = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [serverError, setServerError] = useState(null);
  const [needAuth, setNeedAuth] = useState(false);

  const [page, setPage] = useState(0);
  const [profilePic, setProfilePic] = useState('');
    
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const createTutorialEventData = (page) => {
    let sampleEvents = []

    
    for (let i = 0; i < 10; i++) {
      const number = getRandomNumberWithFixedWidth(256);
      const dates = generateRandomTimes() 
      const sampleEvent = {
        "event_name": `${eventNames[i + (10 * page)]}`,
        "start_date": dates['start_date'],
        "end_date": dates['end_date'],
        "photo_filename": `stock${number}.webp`,
      }

      sampleEvents.push(sampleEvent)
    }

    if (page > 2) {
      const critterNumber = getRandomNumberWithFixedWidth(183)
      setProfilePic(`https://revibe-life-assets.s3.amazonaws.com/critter${critterNumber}.webp`)
    }

    return sampleEvents;
  }

  // Check the auth startus
  // IDEA: Use the id to change the icon at the end
  const fetchUserIdAndSetURL = async () => {
      try {
          const response = await axios.get(userIDAPI);
          const id = response.data.logged_in_as;
        } catch (error) {
          if (error.response?.status === 401) {
            setNeedAuth(true)
            setServerError('Please log in to continue')
          } else {
            setServerError('There was a server error')
          }
        }
  };
      
  useEffect(() => {
    setIsLoading(true);

    fetchUserIdAndSetURL();

    const debounceTimer = setTimeout(() => {

      // IDEA: Create from a remote source 
      const tutorialEventData = createTutorialEventData(page)
      setEvents(tutorialEventData);
      
      setIsLoading(false);
    }, 1000); // 2000ms delay

    // This will clear the timer if the user continues to type, ensuring that the API call is only made 2000ms after the user stops typing
    return () => clearTimeout(debounceTimer);
  }, [page]);

  const handleNextTutorialStep = (id) => {
    console.log(id)
    setPage(oldPage => oldPage + 1)
    setModalIsOpen(true)
  }

  const Welcome = () => (
    <div id='tutorial_group'>
      <h4>
        Revibe Practice
      </h4>
      <h5>
        Welcome to the tutorial!
      </h5>
      <h6>
        Anyone can host an event on Revibe
      </h6>
      <div className='tutorial_forward'>
        <div className={`primary_action_total`}>
          1
        </div>
        <div className='primary_action_buttons'>
          <div className={`primary_action_button tutorial_button`}>
            <FontAwesomeIcon
              icon={faHeartOpen}
              size="2xl" />
          </div>
        </div>
      </div>
      <h6>
        New vibes ( <span><FontAwesomeIcon icon={faHeartOpen} /></span> ) are event invites
      </h6>
      <div className='tutorial_forward'>
        <div className={`primary_action_total`}>
          2
        </div>
        <div className='primary_action_buttons'>
          <div className={`primary_action_button tutorial_button`}>
            <FontAwesomeIcon
              icon={faHeart}
              size="2xl" />
          </div>
        </div>
      </div>
      <h6>
        Change your vibe ( <span><FontAwesomeIcon icon={faHeart} /></span> or <span><FontAwesomeIcon icon={faHeartCrack} /></span> ) to personalize your calendar and to change the vibe count
      </h6>
      <div className='tutorial_forward'>
        <div className={`primary_action_total`}>
          1
        </div>
        <div className='primary_action_buttons'>
          <div className={`primary_action_button tutorial_button`}>
            <FontAwesomeIcon
              icon={faHeartCrack}
              size="2xl" />
          </div>
        </div>
      </div>
    </div>
  );
  
  const Introduction = () => (
    <div id='tutorial_group'>
      <h4>
        Revibe Practice
      </h4>
      <h5>
        Explore Vibes!
      </h5>
      <h6>
        Search for up to 10 events in your area - this tutorial hides the search bar, and returns a simulated result
      </h6>
      <div className='tutorial_forward'>
        <div className='search_nav'>
          <a className='fa-button search_nav-back tutorial_button'>
              <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="xl" />
          </a>
          <div className="search_input-container">
            <input type="text" value={'Front Royal, VA'} placeholder="Explore vibes" readOnly />
          </div>
        </div>
      </div>
      <h6>
        Clearly posted events have a location
      </h6>
      <div className='card'>
        <div className='event_org_info_wrapper'>
          <div className='event_org_info_item'>
            <p className='event_org_info_item_wrapper'>
              <span className='event_org_info_label'>Location</span>
            </p>
            <p className='event_org_info_value'>
              {`Front Royal, VA`}
            </p>
          </div>  
        </div>
      </div>
      <h6>
        View events to see the location
      </h6>
      
      <div className='interaction_buttons'
          style={{justifyContent: 'center'}}>
        <div className='button tutorial_button'>
          <FontAwesomeIcon icon={faBookOpenReader} />
          View Event
        </div>
      </div>
    </div>
  );
  
  const AdvancedConcepts = () => (
    <div id='tutorial_group'>
      <h4>
        Revibe Practice
      </h4>
      <h5>
        Explore Vibes!
      </h5>
      <h6>
        Search with emojis and other text - this tutorial hides the search bar, and returns a simulated result
      </h6>
      <div className='tutorial_forward'>
        <div className='search_nav'>
          <a className='fa-button search_nav-back tutorial_button'>
              <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="xl" />
          </a>
          <div className="search_input-container">
            <input type="text" value={'⭐ Star'} placeholder="Explore vibes" readOnly />
          </div>
        </div>
      </div>
      <h6>
        View up to 10 events based on their title, description, or location
      </h6>
      <div className='card'>
        <div className='tutorial_forward'>
          <div className={`primary_action_total`}>
            1
          </div>
          <div className='primary_action_buttons'>
            <div className={`primary_action_button tutorial_button`}>
              <FontAwesomeIcon
                icon={faHeartOpen}
                size="2xl" />
            </div>
          </div>
          <h3>⭐ Star Symphony</h3>
        </div>
        <div className='event_org_info_wrapper'>
          <div className='event_org_info_item'>
            <h5 className='event_description'>{`Shimmering ⭐ stars shine silently, sweeping seamlessly, so serene`}</h5>
          </div>
          <div className='event_org_info_item'>
            <p className='event_org_info_item_wrapper'>
              <span className='event_org_info_label'>Location</span>
            </p>
            <p className='event_org_info_value'>
              {`⭐ Starland`}
            </p>
          </div>  
        </div>
      </div>
    </div>
  );

  const ImageLoader = ({ src }) => {
    const [loaded, setLoaded] = useState(false);
  
    return (
      <div>
        <img
          className="profile_image"
          src={src}
          alt={"Profile Picture"}
          style={{ display: loaded ? 'inherit' : 'none' }}
          onLoad={() => setLoaded(true)}
        />
        {!loaded && <Loading />}
      </div>
    );
  }

  const Conclusion = () => (
    <div id='tutorial_group'>
      <h6>
        Thank you for trying out our tutorial. Visit our resources page for more information. Have fun with Revibe!
      </h6>
      <div className='user_profile_image_container'>
        <ImageLoader src={profilePic} />
      </div>
      <div className='interaction_buttons'
          style={{justifyContent: 'center'}}>
        <Link 
            to={`${identityURL}user-profile`}
            className="fa-button"
        >
          <div className='button'>

            <FontAwesomeIcon
                icon={faCircleUser}
            />
            Update account
          </div>
        </Link>
      </div>
      <h6>
        Express yourself! Choose from many cute critters like this one. Find a matching critter in your account settings
      </h6>
      <div className='resources c2a'>
        <b>
            <Link to={`${eventURL}resources`}>
                <div className='user-profile-resources_button_container'>
                  <div className="button gradient_button">
                    <FontAwesomeIcon
                        icon={faToolbox}/>
                    Resources
                  </div>
                </div>
            </Link>
            <br />
            Find useful information to create a great vibe!
        </b>
      </div>
    </div>
  );

  const DynamicModal = ({ page, setModalIsOpen, children }) => {
    // Map of page numbers to components
    const pageComponents = {
      0: <Welcome />,
      1: <Introduction />,
      2: <AdvancedConcepts />,
      3: <Conclusion />,
      // Add more components as necessary
    };
  
    // Determine content based on `page`
    const ComponentToRender = pageComponents[page] || <div>End of Tutorial</div>;
  
    // IDEA: Rename the classes
    return (
      <div>
        <Modal className="chat-modal" isOpen={modalIsOpen}>
          <div className='chat_content tutorial_content'>
            <div id='tutorial_content_main_area'>
              {ComponentToRender}
            </div>
              
            { page > 2 &&
              <Link id='tutorial_content_next_button' className="button" to={`${eventURL}`}>
                <FontAwesomeIcon
                  icon={faCalendarDays}/>
                Event Calendar
              </Link>
            }
            {
              page <= 2 &&
              <div id='tutorial_content_next_button' className='button' onClick={() => setModalIsOpen(false)}>
                <FontAwesomeIcon icon={faCheckCircle} size='lg'/>
                  Let's practice
              </div>
            }
          </div>
        </Modal>
          
        {children}
      </div>
    );
  };

  if (serverError) {
    return (
      <div>
        {needAuth &&
          <Loading />
        }
        {!needAuth &&
        <>
          <ErrorMessage errorMessage={serverError} />
          <h5 style={{textAlign: "center", margin: 0}}>
            Try again in a few minutes
          </h5>
        </>
        }
      </div>
    )
  }
  
  return (
    <div>
      <DynamicModal page={page} setModalIsOpen={setModalIsOpen} />
      { isLoading && 
        <Loading />
      }
      { !isLoading &&
        <div className='events_wrapper'>
          <div className='events'>
            {events.length > 0 ? (
              events.map((event, index) => {
                if (index === 0) {
                  return (
                    <div key={index}>
                      <TutorialEvent {...event} handleNextTutorialStep={handleNextTutorialStep} />
                      <h6 className='disclaimer'>
                        Disclaimer: Event visuals use <br />AI-generated stock images.
                      </h6>
                    </div>
                  )
                }
                return <TutorialEvent key={index} {...event} handleNextTutorialStep={handleNextTutorialStep} />
              })
            ) : (
              <div className="no-events-message">
                <h1>No Events Found</h1>
              </div>
            )}
          </div>
        </div>
      }
      
      <div id="tutorial_event_nav" className='event_nav'>
        <strong>
          Practice tapping the <span><FontAwesomeIcon icon={faHeartOpen} /></span> to continue
        </strong>
      </div>

      {/* <div className="navigation-controls">
        <button onClick={() => setPage(oldPage => Math.max(oldPage - 1, 0))}>Previous</button>
        <button onClick={() => setPage(oldPage => oldPage + 1)}>Next</button>
      </div> */}

    </div>
  );
}

export default Tutorial;
