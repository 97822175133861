/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import './StyleGuide.css';

const StyleGuide = () => {
  // You might want to arrange these variables into logical groups
  const mainColorVariables = [
    '--main-01', '--main-02', '--background-01', '--background-02'
  ];

  const swatchColorVariables = [
    '--swatch-01', '--swatch-02', '--swatch-03', '--swatch-04', 
    '--swatch-05', '--swatch-06', '--swatch-07', '--swatch-08', 
    '--swatch-09', '--swatch-10', '--swatch-11', '--swatch-12', 
  ]

  const psiColorVariables = [
    '--elu-01', '--aux-01', '--psi-01', '--kub-01', '--bio-01', '--gen-01',
    '--elu-02', '--aux-02', '--psi-02', '--kub-02', '--bio-02', '--gen-02'
  ];

  const fontVariables = [
    '--font-01', '--font-02', '--weight-01', '--weight-02', '--weight-03'
  ];
  
  const sizeVariables = [
    '--size-01', '--size-02', '--size-03',
    '--size-04', '--size-05', '--size-06'
  ];

  const renderColorSwatches = (colors) => {
    return (
      <div className='swatches'>
        {
          colors.map((color) => (
            <div className="swatch" key={color} style={{ backgroundColor: `var(${color})` }}>
              <span className="swatch-name">{color}</span>
            </div>
          ))
        }
      </div>
    )
  };

  return (
    <div className="style-guide">
      <div>
          <h1>Mission Statement</h1>
          <p>At Revibe, we're committed to 'Refreshing Our World.' Our Digital Board is a unique platform aimed at connecting, enriching, and empowering our vibrant community of Helltown. Together, we strive to foster inclusivity, creativity, education, and innovation, building a future that reflects our shared values and aspirations.</p>

          <h2>Goals and Objectives</h2>
          <h3>Connectivity</h3>
          <p>Bridge gaps and bring neighbors closer through conversations that transcend barriers.</p>
          
          <h3>Education</h3>
          <p>Provide accessible tools for understanding and celebrating our shared cultural heritage.</p>
          
          <h4>Innovation</h4>
          <div>
              <p>Implement user-friendly technology that evolves with our community, ensuring resilience and growth.</p>
          </div>
          
          <h5>Positive Engagement</h5>
          <div>
              <p>Uphold a respectful and uplifting community that encourages positivity and inclusivity.</p>
          </div>
      </div>

      <h6>Revibe's Commitment</h6>
      <p>We're here to create a dynamic space that resonates with our community's values and potential. Join us in making Helltown a place where everyone can thrive.</p>

      <hr></hr>
      <div className="color-swatch-container">
        <h2>Main Colors</h2>
        {renderColorSwatches(mainColorVariables)}
        <h2>Swatch Colors</h2>
        {renderColorSwatches(swatchColorVariables)}
        <h2>Psihesion Colors</h2>
        {renderColorSwatches(psiColorVariables)}
      </div>
    </div>
  );
};

export default StyleGuide;
