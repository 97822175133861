/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import PanImage from '../PanImage/PanImage';
import BlurLoad from '../BlurLoad/BlurLoad';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const PhotoMation = ({ panChoice, width, height, assetIdentifier }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  
  const videoSrc = `https://revibe-life-assets.s3.amazonaws.com/${assetIdentifier}.mp4`;
  const imgSrc = `https://revibe-life-assets.s3.amazonaws.com/${assetIdentifier}.webp`;
  
  const handleReadyStateChange = (newState) => {
    setVideoLoaded(newState === 4);
  };

  return (
    <div>
      {/* Display the image only if the video hasn't loaded yet */}
      {/* Initially display BlurLoad */}
      {!imageLoaded && !videoLoaded && (
        <BlurLoad width={width} height={height} src={''} />
      )}

      {/* Load and render PanImage once the image is fully loaded */}
      <img
        src={imgSrc}
        alt="preload"
        style={{ display: 'none' }}
        onLoad={() => setImageLoaded(true)}
      />

      {/* Conditionally render PanImage when image is ready */}
      {!videoLoaded && imageLoaded && (
        <PanImage
          key={imageLoaded}
          panChoice={panChoice}
          img={imgSrc}
          width={width}
          height={height}
        />
      )}

      <VideoPlayer videoLoaded={videoLoaded} width={width} height={height} src={videoSrc} onReadyStateChange={handleReadyStateChange} />

    </div>
  );
};

export default PhotoMation;
