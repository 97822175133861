/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import './LoginForm.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import config from '../../config';

import ErrorMessage from '../../components/ErrorMessage';

const api = config.createApiUrl('identity', `/auth/login`, config.URL_TYPES.API);

const LoginForm = ({isNewUser, toggleUser}) => {
    const navigate = useNavigate()
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    
    const [showPassword, setShowPassword] = useState(false);
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }    
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            const response = await axios.post(api, {
                email,
                password,
            }, { withCredentials: true });
    
            // If login is successful, navigate to the event main
            if (response?.status === 200) {
                navigate('/')
            } else {
                setErrorMessage('Invalid email or password');
            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setErrorMessage(error.response.data.message);  // Display the error message from server
            } else if (error instanceof TypeError) {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage('The request took too long. The server might be down. Please try again later.');
            } else {
                setErrorMessage('An unexpected error occurred. Please refresh the page or check your internet connection.');
            }
        }
    };

    return (
        <div className='form-container'>
            <form onSubmit={handleSubmit}>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className='input-group'>
                    <div className='input-container'>
                        <label>
                            Email
                        </label>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            placeholder="Email" 
                            required 
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                <div className='input-group'>
                    <div className='input-container'>
                        <label>
                            Password
                        </label>
                        <div className='login-input'>
                            <input 
                                type={showPassword ? 'text' : 'password'}
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                placeholder="Password" 
                                required 
                                onKeyDown={handleKeyDown}
                            />
                            <div className="login-input-toggle" onClick={togglePasswordVisibility}>
                                {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={handleSubmit} className="form_button button">
                    Log In
                </div>
                <div className="form_toggle" onClick={() => toggleUser()}>
                    {isNewUser ? "Existing User? Log In" : "New User? Sign Up"}
                </div>
            </form>
        </div>
    );
};

export default LoginForm;
