/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ErrorMessage from '../../components/ErrorMessage';
import Section from '../../components/Section';
import TitleSection from '../../components/TitleSection';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';

import './BudgetQuickStart.css';

import { useNavigate } from 'react-router-dom';

const default_data = {
    income: [
        {
            amount: 0,
            label: 'Primary Job', 
            caption: 'Main income',
        },
        {
            amount: 0,
            label: 'Side Hustle', 
            caption: 'Side income',
        },
        {
            amount: 0,
            label: 'Investments', 
            caption: 'IRA/Dividends',
        }
    ],
    expenses: [
        {
            amount: 0,
            label: 'Rent', 
            caption: 'Mortgage/Rent',
        },
        {
            amount: 0,
            label: 'Food', 
            caption: 'Groceries',
        },
        {
            amount: 0,
            label: 'Everything Else', 
            caption: 'Etc.',
        }
    ]
};

const BudgetQuickstart = () => {
    const navigate = useNavigate();
    
    const [lineItems, setLineItems] = useState({ income: [], expenses: [] });

    const [errorMessage, setErrorMessage] = useState(null)

    // Simulate fetching data from an API on component mount
    useEffect(() => {
        /*
        * IDEA:
        * In a production setting with a backend, we would use an API call to load the data
        */
        
        const storedData = JSON.parse(localStorage.getItem("budget_data"));
        if (storedData) {
            setLineItems(storedData);
        } else {
            // Handle the case where there is no data in localStorage
            console.error('No data found in localStorage');
            // Set some default data
            setLineItems(default_data);
            // We want to add data to localStorage if it is null.
            localStorage.setItem("budget_data", JSON.stringify(default_data)); 
        }

    }, []);
    
    const calculateTotalAmount = (items) => items.reduce((acc, item) => acc + item.amount, 0);
    
    // IDEA: Move to a shared utility file with other validation
    const hasErrors = () => {
        // Calculate total income and expenses
        const totalIncome = calculateTotalAmount(lineItems.income);
        const totalExpenses = calculateTotalAmount(lineItems.expenses);

        if (totalIncome < 1 && totalExpenses < 1) {
            // IDEA: Create a lookup for error messages
            setErrorMessage('Are you sure?')
            return true
        }
        return false
    }

    const updateBudget = (override) => {
        let update = true;
        const errors = hasErrors();

        if (!override && errors) {
            update = false;
        }

        if (update) {
            // IDEA: Change localStorage to API call   
            localStorage.setItem("budget_data", JSON.stringify(lineItems)); 
            navigate('/budget-main');
        }
    }
    
    const addLineItem = (section) => {
        const newItem = {
            amount: 0,
            label: 'New Item',
            caption: 'Add a description',
        };
        setLineItems(prevItems => {
            return {
                ...prevItems,
                [section]: [...prevItems[section], newItem],
            };
        });
    };

    const handleRemoveItem = (section, index) => {
        setLineItems(prevItems => {
            const newSection = prevItems[section].filter((_, itemIndex) => index !== itemIndex);
            return { ...prevItems, [section]: newSection };
        });
    };

    const handleAmountChange = (section, index, newValue) => {
        setLineItems(prevItems => {
            // Directly update the amount value of the item at the given index
            const newSection = prevItems[section].map((item, itemIndex) =>
                index === itemIndex ? { ...item, amount: newValue } : item
            );
    
            // Return new state with the updated section
            return { ...prevItems, [section]: newSection };
        });
    };

    const handleLabelCaptionChange = (section, index, field, newValue) => {
        setLineItems(prevItems => {
            const newSection = prevItems[section].map((item, itemIndex) =>
                index === itemIndex ? { ...item, [field]: newValue } : item
            );
            return { ...prevItems, [section]: newSection };
        });
    };

    return (
        <div>
            <div id='quick-page'>
                <TitleSection 
                    title={'Quick Simple Budget'}
                    microcopy={'Create a quick estimate that can be improved to include more than the basics'}  
                />
                <Section
                    title="Income"
                    items={lineItems.income}
                    section="income"
                    onAmountChange={handleAmountChange}
                    onAddLineItem={addLineItem}
                    onRemoveItem={handleRemoveItem}
                    isEditing={false}
                    onLabelCaptionChange={handleLabelCaptionChange}
                />
                <Section
                    title="Expenses"
                    items={lineItems.expenses}
                    section="expenses"
                    onAmountChange={handleAmountChange}
                    onAddLineItem={addLineItem}
                    onRemoveItem={handleRemoveItem}
                    isEditing={false}
                    onLabelCaptionChange={handleLabelCaptionChange}
                />
            </div>
            <div className='footer-nav' style={{zIndex: 0}}>
                <Link to={`/resources/`}>
                    <div className="fa-button">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            size={"xl"}
                        />
                    </div>
                </Link>

                <div id="save" onClick={() => updateBudget(errorMessage)}
                    className="fa-button">
                    <FontAwesomeIcon
                        icon={faSave}
                        size={"xl"}
                    />
                    {errorMessage &&
                        <span className="errorMessage">
                            {errorMessage}
                        </span>
                    }
                </div>
            </div>
        </div>
    );
};

export default BudgetQuickstart;
