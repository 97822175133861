/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect, useMemo } from 'react';
import logoBlock from '../../logo-emblem.png'; // Adjust this path to your actual image path

import axios from 'axios';
import { Link } from 'react-router-dom';

import Loading from '../Loading';
import ErrorMessage from '../ErrorMessage';
import LocalLinks from '../LocalLinks';

import PhotoMation from '../PhotoMation';

import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import './TutorialEvent.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartCrack, faHourglassStart, faHourglassEnd, faTimeline, faBookOpenReader, faLink, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';

import { useOrganizations } from '../../context/OrganizationContext';

import config from '../../config';

import default01 from './images/default01.png'

const api = config.createApiUrl('events', `/event/`, config.URL_TYPES.API);

// IDEA: Move this to a config file (loaded from static or S3)
const interactionLookup = {
  'primaryInterest': 1,
  'secondaryInterest': 2,
  'socialSupport': 3
}


const EventCard = ({
  id,
  event_name,
  start_date,
  end_date,
  event_type,
  minimum_age,
  maximum_age,
  specific_location,
  description,
  organization_id,
  photo_filename,
  isPage,
  handleNextTutorialStep
}) => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [plusInteractionCount, setPlusInteractionCount] = useState(null);
  const [userLatestStatus, setUserLatestStatus] = useState(null);

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  // IDEA: Maybe name 'view' and 'add' differently 
  const viewInteractionAPI = config.createApiUrl('events', `/interaction/event/${id}/count`, config.URL_TYPES.API)
  const addInteractionAPI = config.createApiUrl('events', `/interaction/event/${id}`, config.URL_TYPES.API)
  const thisEventURL = config.createApiUrl('events', `/event/${id}`, config.URL_TYPES.BASE)
  
  const [animState, setAnimState] = useState(false);
  

  useEffect(() => {
    setIsLoading(true);
    
    let total = Math.round(Math.random() * 10);

    setUserLatestStatus("")
    setPlusInteractionCount(total);
    setIsLoading(false);
  }, [id]);

  const saveInteraction = (interaction) => {
    setIsLoading(true);

    setAnimState(true);
    setTimeout(() => {
      setAnimState(false);
      
      // setTimeout(() => {
        handleNextTutorialStep(id);
      // }, 2000); // 1000 milliseconds = 1 second
      
    }, 2000); // 1000 milliseconds = 1 second
    
    setUserLatestStatus("created")
    setPlusInteractionCount(plusInteractionCount + 1);
    setIsLoading(false);
  }

  const viewOrg = () => {
    const directoryURL = config.createApiUrl('directory', `/public/groups/${organization_id}`, config.URL_TYPES.BASE);
    
    window.location = directoryURL;
  }

  /*
   * Event w/Email Formatting
   */

  const optionsDate = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
  
  function formatAgeRange(minimum_age, maximum_age) {
    minimum_age = Math.round(minimum_age);
    maximum_age = maximum_age != null ? Math.round(maximum_age) : null;
    if (minimum_age === maximum_age) return maximum_age
    if (minimum_age === 0 && maximum_age === 100) return 'All ages';
    if (maximum_age === 100) return `Above ${minimum_age}`;
    if (minimum_age === 0) return `Under ${maximum_age}`;
    return `${minimum_age} - ${maximum_age}`;
  }
  
  const fAgeRange = formatAgeRange(minimum_age, maximum_age);

  let fStart, fEnd, fStartDate, fEndDate, 
      differenceInMilliseconds, differenceInDays, differenceInHours, 
      sharedText, xSharedText;

  // Calculate the difference in milliseconds
  differenceInMilliseconds = end_date - start_date;
  differenceInDays = Math.round(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  differenceInHours = Math.round(differenceInMilliseconds / (1000 * 60 * 60));

  if (new Date(start_date).toDateString() === new Date(end_date).toDateString()) {
    // The event is on the same day
    fStartDate = new Date(start_date).toLocaleDateString('en-US', optionsDate);
    fEndDate = new Date(end_date).toLocaleDateString('en-US', optionsDate);
    fStart = new Date(start_date).toLocaleTimeString('en-US', optionsTime);
    // Remove leading zero from hours if present
    fStart = fStart.replace(/^0(?:0:)?/, '');
    fEnd = new Date(end_date).toLocaleTimeString('en-US', optionsTime);
    // Remove leading zero from hours if present
    fEnd = fEnd.replace(/^0(?:0:)?/, '');

    sharedText = `
    Check out this event I found on Revibe: ${thisEventURL}

    Event Name: ${event_name}
    Date: ${fStartDate}
    Start Time: ${fStart}
    End Time: ${fEnd}
    Age Range: ${fAgeRange}
    Location: ${specific_location == null ? 'Not specified' : specific_location}
    Description: ${description}
    
    Duration: ${differenceInHours} Hour(s)
     
   `;

   xSharedText = `
     Check out this event I found on #Revibe!

     Event Name: ${event_name}
     Date: ${fStartDate}
     
    `;
  } else {
    // The event spans multiple days
    fStartDate = new Date(start_date).toLocaleDateString('en-US', optionsDate);
    fEndDate = new Date(end_date).toLocaleDateString('en-US', optionsDate);
    fStart = new Date(start_date).toLocaleTimeString('en-US', optionsTime);
    // Remove leading zero from hours if present
    fStart = fStart.replace(/^0(?:0:)?/, '');
    fEnd = new Date(end_date).toLocaleTimeString('en-US', optionsTime);
    // Remove leading zero from hours if present
    fEnd = fEnd.replace(/^0(?:0:)?/, '');

    sharedText = `
     Check out this event I found on Revibe: ${thisEventURL}

     Event Name: ${event_name}
     Date: ${fStartDate} at ${fStart} to ${fEndDate} at ${fEnd}
     Age Range: ${fAgeRange}
     Location: ${specific_location == null ? 'Not specified' : specific_location}
     Description: ${description}
    
     Duration: ${differenceInDays} Day(s)

    `;

    xSharedText = `
     Check out this event I found on #Revibe!

     Event Name: ${event_name}
     Date: ${fStartDate} - ${fEndDate}
     
    `;
  }

  const CardImage = ({photo_filename}) => {
    if (photo_filename) {
      const assetIdentifier = photo_filename.split('.')[0];
      return (
        <div className='event_image'>
          <PhotoMation 
            panChoice={2} 
            assetIdentifier={assetIdentifier} 
          />
        </div>
      )
    } else {
      return (
        <img className="event_image" src={default01} />
      );
    }
  }

  const memoizedCardImage = useMemo(() => <CardImage photo_filename={photo_filename} />, [photo_filename]);

  return (
    <>
      <div className='card'>
        {memoizedCardImage}
        <div className='forward'>
          <div className={`primary_action_total ${animState ? 'active' : ''}`}>
            {plusInteractionCount}
          </div>
          <div className='primary_action_buttons'>
            <div onClick={() => {
              if(!animState) {
                saveInteraction('primaryInterest')
              }
            }}
              className={`primary_action_button ${animState ? 'active' : ''}`}>
              { !userLatestStatus &&
                <FontAwesomeIcon
                  icon={faHeartOpen}
                  beat={animState}
                  size="2xl" />
              }
              { userLatestStatus === 'created' &&
                <FontAwesomeIcon
                  icon={faHeart}
                  beatFade={animState}
                  size="2xl" />
              }
              { userLatestStatus === 'recreated' &&
                <FontAwesomeIcon
                  icon={faHeart}
                  beat={animState}
                  size="2xl" />
              }
              { userLatestStatus === 'uncreated' &&
                <FontAwesomeIcon
                  icon={faHeartCrack}
                  shake={animState}
                  size="2xl" />
              }
            </div>
          </div>

          <h3>{event_name}</h3>
          <h5>
            {fStartDate === fEndDate ? (
              <>
                <div className='time_label_timeline'>
                  <FontAwesomeIcon icon={faTimeline} />
                  <span>Duration: {differenceInHours} Hour(s)</span>
                </div>
                {fStartDate}
                <br />
                {fStart && fEnd ? `${fStart} - ${fEnd}` : (fStart || fEnd || '')}
              </>
            ) : (
              <>
                <div className='time_label_timeline'>
                  <FontAwesomeIcon icon={faTimeline} />
                  <span>Duration: {differenceInDays} Day(s)</span>
                </div>
                <div className='time_label'>
                  <FontAwesomeIcon icon={faHourglassStart} /> 
                  <span>Starts</span>
                </div>
                <div>{fStartDate}</div>
                <div>{fStart}</div>

                <div className='time_label'>
                  <FontAwesomeIcon icon={faHourglassEnd} />
                  <span>Ends</span>
                </div>
                <div>{fEndDate}</div>
                <div>{fEnd}</div>
              </>
            )}
          </h5>

          <div className="share_buttons_vertical">
            <div
              className={`share_button vertical_share_button 
              tutorial_button ${showCopiedMessage ? 'active' : ''}`} 
            >
              <FontAwesomeIcon width={32} icon={faLink} />
            </div>
          </div>
          <div className="share_buttons">
            <FacebookShareButton
              disabled
              quote={sharedText}
              hashtag={"revibe"}
              url='https://www.revibe.life'
              className="share_button tutorial_button"
            >
              <svg viewBox="0 0 64 64" width="32" height="32">
                <circle cx="32" cy="32" r="32" fill="#0965FE"></circle>
                <path d="M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z" fill="white"></path>
              </svg>
            </FacebookShareButton>

            <TwitterShareButton
              disabled
              title={xSharedText}
              url='revibe.life'
              className="share_button tutorial_button"
            >
              <svg viewBox="0 0 64 64" width="32" height="32">
                <circle cx="32" cy="32" r="32" fill="#000000"></circle>
                <path d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z" fill="white"></path>
              </svg>
            </TwitterShareButton>

            <EmailShareButton
              disabled
              subject={`Event Details: ${event_name}`}
              body={sharedText}
              url="Improve with Revibe! Find more local events. https://www.revibe.life"
              className="share_button tutorial_button"
            >
              <svg viewBox="0 0 64 64" width="32" height="32">
                <circle cx="32" cy="32" r="31" fill="#ff5bad"></circle>
                <path d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z" fill="white"></path>
              </svg>
            </EmailShareButton>
          </div>

        </div>
        <div className='event_org_info_wrapper'>
          
        </div>
        
        <div className='interaction_buttons'
            style={{justifyContent: 'space-between'}}>
          <div className='button tutorial_button'>
            <FontAwesomeIcon icon={faBookOpenReader} />
            View Event
          </div>
          <div className='button tutorial_button'>
            <FontAwesomeIcon icon={faPeopleGroup} />
            View Group
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
