/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

import './EventListPreview.css';

import Loading from '../Loading';
import PhotoMation from '../PhotoMation';
import config from '../../config';

import default01 from '../Event/images/default01.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight, faSearch } from '@fortawesome/free-solid-svg-icons';

const api = config.createApiUrl('events', `/event/`, config.URL_TYPES.API);
const eventURL = config.createApiUrl('events', `/event/`, config.URL_TYPES.BASE);
const searchURL = config.createApiUrl('events', `/search/`, config.URL_TYPES.BASE);

const CardImage = ({photo_filename}) => {
  if (photo_filename) {
    const assetIdentifier = photo_filename.split('.')[0];
    return (
      <div className='event_image'>
        <PhotoMation 
          panChoice={2} 
          assetIdentifier={assetIdentifier} 
        />
      </div>
    )
  } else {
    return (
      <img className="event_image" src={default01} />
    );
  }
}

const EventHighlight = ({id, event_name, photo_filename}) => {
  const memoizedCardImage = useMemo(() => <CardImage photo_filename={photo_filename} />, [photo_filename]);
  return (
    <div className='card'>
        <div className='small_event'>
          {memoizedCardImage}
        </div>
        
        <div className='forward'>
          <div className='event_name'>
            {event_name}
          </div>
          <div className='event-view_vibe'>
            <Link to={`${eventURL}${id}`} >
              <span className='link_to_vibe'>
                <FontAwesomeIcon
                    icon={faCircleRight}
                />
                View
              </span>
            </Link>
          </div>
        </div>
      </div>
  )
}

const EventListPreview = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${api}?page=${1}&search=${''}`)
      .then(response => {
        setEvents(response.data.events);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
      });

  }, []);

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <div id='event-list-preview'>
      <div>
        <h6 className='guide_text'>
            Revibe shares events, content, learning, creativity, connections and other vibes
        </h6>
        <div className='events'>
          {events.length > 0 ? (
              events.map((event, index) => {
                if (index === 0) {
                  return (
                    <div key={index}>
                      <EventHighlight {...event} />
                      <h6 className='disclaimer'>
                        Disclaimer: Event visuals use <br />AI-generated stock images.
                      </h6>
                    </div>
                  )
                }
                return <EventHighlight key={index} {...event} />
              })
            ) : (
              <h6 className='disclaimer' style={{marginTop: "1rem"}}>
                The vibe is quiet right now. Share your content to boost the vibe!
              </h6>
            )}
        </div>
      </div>
      <h6 className='guide_text'>
        <Link to={`${searchURL}`} >
          <div className='button gradient_button guide_text'>
              <FontAwesomeIcon icon={faSearch} />
              Explore Vibes
          </div>
        </Link>
        <br />
        or log in to save your vibe!
      </h6>
    </div>
  );
};

export default EventListPreview;
