/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import './Releases.css';
import v1_0_0_release_cover from './images/v1.0.0_release_cover.png'
import v1_1_0_release_cover from './images/v1.1.0_release_cover.png'
import v1_2_0_release_cover from './images/v1.2.0_release_cover.png'

import { Link } from 'react-router-dom'

import logoBlock from '../../logo-emblem.png'; // Adjust this path to your actual image path
import logoBlockDark from '../../logo-emblem-metal.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBook } from '@fortawesome/free-solid-svg-icons';

const version1_0_0ManualURL = "https://revibe-life-assets.s3.amazonaws.com/Revibe_Life_v1.0.0_User_Manual.pdf"
const version1_1_0ManualURL = "https://revibe-life-assets.s3.amazonaws.com/Revibe_Life_v1.1.0_User_Manual.pdf"
const version1_2_0ManualURL = "https://revibe-life-assets.s3.amazonaws.com/Revibe_Life_v1.2.0_User_Manual.pdf"

const ResourcesOption = ({ icon, title, subtitle, isInActive, to }) => (
  <Link to={to}>
    <div className={`resources_option ${isInActive ? 'isInActive' : ''}`}>
      <FontAwesomeIcon icon={icon} size="xl" />
      <div className="text_container">
        <h5>{title}</h5>
        <h6>{subtitle}</h6>
      </div>
    </div>
  </Link>
);

const Releases = () => {

  const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener('change', handleChange);

    return () => darkModeMediaQuery.removeEventListener('change', handleChange);
  }, []);

  return (
    <>
      <div className='releases'>
        <div className="logo_wrapper">
          <img className="logo-block" src={isDarkMode ? logoBlockDark : logoBlock} alt="Revibe Logo" />
        </div>

        <h1>Releases</h1>
        <div>
          <h5>Reflect on Revibe History</h5>
          <p>
          We document every significant update and improvement to Revibe. Release notes provide detailed information about these updates, including changes, improvements, and new features introduced in each version of the platform. Our aim is to offer a clear and comprehensive history of our development journey, highlighting the milestones that have shaped Revibe. By reading these updates, users can appreciate the evolution of Revibe, understand our commitment to continuous improvement, and stay informed about the latest features and enhancements. This helps us all maintain the highest vibes while we foster unity.
          </p>
        </div>

        <h4>Version 1.2.0</h4>
        <ResourcesOption
            to={version1_2_0ManualURL}
            isInActive={false}
            icon={faBook}
            title="User Manual"
            subtitle="August 6, 2024" />
        <p>
        Enhanced event searching with a location filter and date range selection is now possible in Revibe Version 1.2.0. Users can now search for events by title, description, or location using a new "Search" input field. This allows for quick and efficient event discovery. The "Filter by Area" feature (location filter) enables users to specify a location using a zip code or partial address. This filter works in conjunction with the search field to provide more targeted results. For instance, searching for events in <b>Front Royal, VA</b> will yield events specifically in that area.
        </p>
        <img className="cover_image" src={v1_2_0_release_cover} />
        <p>
        Like in the group calendar, date range selection allows users to set the date range for events they are interested in by selecting start and end dates and times. Search historical and future events within your timeframe and location!
        </p>

        <h4>Version 1.1.0</h4>
        <ResourcesOption
            to={version1_1_0ManualURL}
            isInActive={false}
            icon={faBook}
            title="User Manual"
            subtitle="July 1, 2024" />
        <p>
          Version 1.1.0 of Revibe brings several exciting future features aimed at improving user navigation and overall experience. The update includes enhanced navigation from the main page. Additionally, the main page redesign aims to eliminate the need for forced login, allowing users to explore the platform freely before committing to sign up. A new redirect feature has been added to ensure that new users who agree to the 'Good Vibes' terms are taken directly to the link they intended to visit, rather than being redirected to the search page. Furthermore, this dedicated release notes page provides users with easy access to detailed information about all updates and improvements.
        </p>
        <img className="cover_image" src={v1_1_0_release_cover} />
        <p>
          The Revibe platform introduces a suite of new features crafted to enhance user experience and streamline event management, thanks to valuable feedback from our users in Front Royal, VA. <b style={{textDecoration: "underline"}}>Thank you, Front Royal!</b> Now, users who are part of a group can effortlessly add events to their calendars with the "Add Event" button, making it easier to manage and energize their chosen vibes. For those not yet in a group, the platform offers a smooth onboarding process with the "Create a free group" button, enabling them to subscribe and start creating events and vibes, thereby enhancing their organizational capabilities. These enhancements not only simplify the event creation process but also motivate users to form and join groups, fostering greater unity and interaction. We extend our heartfelt thanks to our users in Front Royal, VA, for their invaluable assistance in helping us refresh our world—together!
        </p>

        <h4>Version 1.0.0</h4>
        <ResourcesOption
            to={version1_0_0ManualURL}
            isInActive={false}
            icon={faBook}
            title="User Manual"
            subtitle="May 29, 2024" />
        <p>
          Revibe Life v1.0.0 is a dynamic digital platform designed to unite groups and foster meaningful connections through event organization, discussions, and activity management. It offers a user-friendly, mobile-first interface that allows access without the need for login credentials for exploration. Key features include customizable group management, an interactive calendar, simple event creation with AI-generated visuals, and a range of engagement icons to facilitate interactions. The platform supports a free usage model with optional paid features, and it continuously evolves to meet user needs.
        </p>
        <img className="cover_image" src={v1_0_0_release_cover} />
        <p>
          We encourage user feedback! Additional resources include tools (e.g. a budgeting microapp) and forums for discussions. Detailed subscription and event management functionalities enhance the overall usability of Revibe. For more information, read our user manual.
        </p>
      </div>
      
      <div className='event_nav' style={{zIndex: 0}}>
          <Link to={`/resources/`}>
            <div className="fa-button">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    size={"xl"}
                />
            </div>
          </Link>
      </div>
    </>
  );
};

export default Releases;
