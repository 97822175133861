/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';

import ErrorMessage from '../../components/ErrorMessage';
import LineItemsDocument from '../../components/LineItemsDocument';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFilePdf, faBalanceScale, faBalanceScaleLeft, faBalanceScaleRight, faPencil } from '@fortawesome/free-solid-svg-icons';

import './BudgetMainPage.css';
import TitleSection from '../../components/TitleSection';


// This is your data structure, e.g.
const default_data = {
    income: [
        {
            amount: 0,
            label: 'Primary Job', 
            caption: 'Main income',
        },
        {
            amount: 0,
            label: 'Side Hustle', 
            caption: 'Side income',
        },
        {
            amount: 0,
            label: 'Investments', 
            caption: 'IRA/Dividends',
        }
    ],
    expenses: [
        {
            amount: 0,
            label: 'Rent', 
            caption: 'Mortgage/Rent',
        },
        {
            amount: 0,
            label: 'Food', 
            caption: 'Groceries',
        },
        {
            amount: 0,
            label: 'Everything Else', 
            caption: 'Etc.',
        }
    ]
};

// IDEA: Refactor to a utility folder
function addCommas(number) {
    // Convert number to string and use regex to add commas
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const BudgetRollup = ({ totalIncome, totalExpenses }) => {

    return (
        <div id='budget-rollup'>
            {totalIncome > totalExpenses && (
                <p id='income-message'>Income is <span id='amount-difference'>${addCommas(Math.abs(totalIncome - totalExpenses))}</span> more than your expenses</p>
            )}
            {totalIncome < totalExpenses && (
                <p id='income-message'>Expenses are <span id='amount-difference'>${addCommas(Math.abs(totalIncome - totalExpenses))}</span> more than your income</p>
            )}
            {totalIncome === totalExpenses && (
                <p id='equal'>Your income and expenses are equal</p>
            )}
        </div>
    )
}

function truncateCharacters(str, maxLength) {
    if (str.length <= maxLength) {
      return str; // Returns original string if string is equal to or less than max length
    } else {
      const truncatedString = str.substring(0, maxLength); // Get the substring up to the maxLength
      // Find the last space within the truncated substring to avoid cutting off a word
      const lastSpaceIndex = truncatedString.lastIndexOf(' ');
      if (lastSpaceIndex !== -1) {
        return truncatedString.substring(0, lastSpaceIndex) + '...'; // Add dots at the last space
      } else {
        return truncatedString + '...'; // If no space found, add dots at the end of the substring
      }
    }
}



const BudgetMainPage = () => {
    const [lineItems, setLineItems] = useState({ income: [], expenses: [] });
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);

    useEffect(() => {
        /*
         * IDEA:
         * In a production setting with a backend, we would use an API call to load the data
         */
        
        const storedData = JSON.parse(localStorage.getItem("budget_data"));
        if (storedData) {
            setLineItems(storedData);

            // Store total income
            setTotalIncome(
                totalAmounts(storedData.income)
            )
            setTotalExpenses(
                totalAmounts(storedData.expenses)
            )
        } else {
            // Handle the case where there is no data in localStorage
            console.error('No data found in localStorage');
            // Set some default data
            setLineItems(default_data);
            // We want to add data to localStorage if it is null.
            localStorage.setItem("budget_data", JSON.stringify(default_data)); 
        }

    }, []);

    const totalAmounts = (lineItemsSection) => {
        return lineItemsSection.reduce((total, item) => total + Number(item.amount), 0);
    }

    const CashFlowItems = ({section}) => {
        if (section in lineItems) {
            // First, sort the items based on the amount in descending order
            const sortedItems = lineItems[section].sort((a, b) => b.amount - a.amount);
    
            // Then, slice the array to get the top 3 items
            const topThreeItems = sortedItems.slice(0, 3);
    
            // Finally, map over the topThreeItems to return the JSX
            return (
                topThreeItems.map((item, index) => 
                    <div key={`${index}-income-${item.label}`} className="cash-flow-item">
                        <span>{truncateCharacters(item.label, 14)}</span>
                    </div>
                )
            );
        }
        // In case the section does not exist in lineItems
        return null;
    };
    

    return (
        <div>
            <div className="financial-summary-card">
                {lineItems && (
                    <div>
                        <div className="summary-header">
                            <TitleSection
                                title={'Summary'}
                                microcopy={'Compare income and expenses to create a balanced budget'}
                            />
                            <BudgetRollup totalIncome={totalIncome} totalExpenses={totalExpenses} />
                        </div>
                        <div className='more-income'>
                            {totalIncome > totalExpenses && (
                                <FontAwesomeIcon icon={faBalanceScaleLeft} size={"xl"} />
                            )}
                            {totalIncome < totalExpenses && (
                                <FontAwesomeIcon icon={faBalanceScaleRight} size={"xl"} />
                            )}
                            {totalIncome === totalExpenses && (
                                <FontAwesomeIcon icon={faBalanceScale} size={"xl"} />
                            )}
                        </div>
                        <div className="financial-overview">
                            <div className="income-section">
                                <h4>Income</h4>
                                <p className='income-number'>${addCommas(totalIncome)}</p>
                            </div>
                            <div className="expenses-section">
                                <h4>Expenses</h4>
                                <p className='expenses-number'>${addCommas(totalExpenses)}</p>
                            </div>
                        </div>
                        <div className="cash-flow-story">
                            <h4>Cash Flow Story Highlights</h4>
                            <div className='two-column-box'>
                                <div>
                                    {lineItems['income'].length > 0 &&
                                        <CashFlowItems section={'income'} />
                                    }
                                </div>
                                <div>
                                    {lineItems['expenses'].length > 0 &&
                                        <CashFlowItems section={'expenses'} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="suggestion">
                            Try to lower expenses first, and then increase income, if possible.
                        </div>
                    </div>
                )}
            </div>
            <div className='footer-nav' style={{zIndex: 0}}>
                <Link to={`/resources/`}>
                    <div className="fa-button">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            size={"xl"}
                        />
                    </div>
                </Link>

                 <div className="fa-button">
                    <PDFDownloadLink
                        document={<LineItemsDocument lineItems={lineItems} totalIncome={totalIncome} totalExpenses={totalExpenses} />}
                        fileName={`budget-summary-from-revibe_${new Date().toISOString()}.pdf`}
                        >
                        {({ blob, url, loading, error }) =>
                            loading ? 
                            <FontAwesomeIcon
                                icon={faFilePdf}
                                size={"xl"}
                            /> : 
                            <FontAwesomeIcon
                                icon={faFilePdf}
                                size={"xl"}
                            />
                        }
                    </PDFDownloadLink>
                </div>

                <Link
                    className="fa-button"
                    to={`${'/budget-edit'}`}
                    >
                        <FontAwesomeIcon
                            icon={faPencil}
                            size="xl" />
                </Link>
            </div>
        </div>
    );
};

export default BudgetMainPage;
