/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Event from '../../components/Event';

import SearchNav from '../../components/SearchNav';
import Loading from '../../components/Loading';

import './Search.css'

import config from '../../config';
import C2AMessage from '../../components/C2AMessage';

const api = config.createApiUrl('events', `/event/`, config.URL_TYPES.API);

const Search = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setIsLoading(true);
      axios.get(`${api}?page=${page}&search=${search}`)
        .then(response => {
          setEvents(response.data.events);
          setIsLoading(false);
        })
        .catch(err => {
          console.error(err);
          setIsLoading(false);
        });
    }, 2000); // 2000ms delay

    // This will clear the timer if the user continues to type, ensuring that the API call is only made 2000ms after the user stops typing
    return () => clearTimeout(debounceTimer);
  }, [page, search]);

  const handleSearch = event => {
    setPage(1);
    setSearch(event.target.value);
  };
    
  if (isLoading) {
      return (
        <div className='events_wrapper'>
          <Loading />
          <SearchNav search={search} handleSearch={handleSearch}/>
        </div>
      )
  }
  
  return (
    <div>
      <div className='events_wrapper'>
        <div className='events'>
          {events.length > 0 ? (
            events.map((event, index) => {
              if (index === 0) {
                return (
                  <div key={index}>
                    <Event {...event} />
                    <h6 className='disclaimer'>
                      Disclaimer: Event visuals use <br />AI-generated stock images.
                    </h6>
                  </div>
                )
              }
              return <Event key={index} {...event} />
            })
          ) : (
            <div className="no-events-message">
              <h1>No Events Found</h1>
              <h5>Try a Different Search</h5>
            </div>
          )}
        </div>
      </div>
      <div id='search_events-c2a'>
        <C2AMessage
            headline="Find more vibes to experience"
            actionText="Explore"
            actionLink={`/query`}
            features="Unlock your next new adventure."
            cost="Refresh your world!"
        />
      </div>
      <SearchNav search={search} handleSearch={handleSearch}/>
    </div>
  );
}

export default Search;
