/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Link } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#bbe4eb',
    padding: 24,
  },
  section: {
    margin: 10,
    padding: 16,
    borderRadius: 8,
  },
  comparisonSection: {
    margin: 10,
    padding: 16,
    backgroundColor: '#f5ffff',
    border: '1px inset #093A69',
    borderRadius: 8,
  },
  sectionHeading: {
    fontSize: 24,
    color: '#093A69',
    marginBottom: 16,
  },
  footer: {
    fontSize: 10,
    color: '#093A69',
    textAlign: 'center',
  },
  comparisonText: {
    fontSize: 15,
    textAlign: 'center',
    color: '#093A69',
    marginTop: 10,
    marginBottom: 10
  },
  item: {
    backgroundColor: '#093A69',
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
  },
  itemLabel: {
    fontSize: 16,
    color: '#cde6ed',
    marginBottom: 4,
  },
  itemAmount: {
    fontSize: 14,
    textAlign: 'right',
    color: '#f5ffff', // This color is for the amount, to make it stand out
  },
  itemCaption: {
    fontSize: 12,
    marginBottom: 8,
    color: '#3E96BA', // Lighter color for the caption
  },
  link: {
    color: '#093A69', // You can choose any color for the hyperlink
    textDecoration: 'underline'
  }
});

// Create Document Component
const LineItemsDocument = ({ lineItems, totalIncome, totalExpenses }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.comparisonSection}>
        <Text style={styles.comparisonText}>
          {totalIncome > totalExpenses ? `Income is $${totalIncome - totalExpenses} more than your expenses` : ''}
          {totalIncome < totalExpenses ? `Expenses are $${totalExpenses - totalIncome} more than your income` : ''}
          {totalIncome === totalExpenses ? 'Your income and expenses are equal' : ''}
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Income</Text>
        {lineItems.income.map((item, index) => (
          <View key={index} style={styles.item}>
            <Text style={styles.itemLabel}>{item.label}</Text>
            <Text style={styles.itemCaption}>{item.caption}</Text>
            <Text style={styles.itemAmount}>${item.amount}</Text>
          </View>
        ))}
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Expenses</Text>
        {lineItems.expenses.map((item, index) => (
          <View key={index} style={styles.item}>
            <Text style={styles.itemLabel}>{item.label}</Text>
            <Text style={styles.itemCaption}>{item.caption}</Text>
            <Text style={styles.itemAmount}>${item.amount}</Text>
          </View>
        ))}
      </View>
      <View style={styles.section}>
        <Text style={styles.footer}>
            Thank you for vibing with us! Update your budget again at{' '}
            <Link style={styles.link} src="http://revibe.life">revibe.life</Link>
        </Text>
      </View>
    </Page>
  </Document>
);

export default LineItemsDocument;
