/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className='loading'>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loading;
