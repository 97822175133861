/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import './Mission.css';
import mission_group from './images/mission_group.webp'

import { Link } from 'react-router-dom'

import logoBlock from '../../logo-emblem.png'; // Adjust this path to your actual image path
import logoBlockDark from '../../logo-emblem-metal.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import C2AMessage from '../../components/C2AMessage';

// IDEA: Refactor to a common component
const LegalItem = ({ href, icon, title, description }) => (
  <div className="legal-item card">
    <a href={href} target="_blank" rel="noopener noreferrer" className="legal-link">
      <div className="legal-content">
        <h5>{title}</h5>
        <h6>{description}</h6>
      </div>
    </a>
  </div>
);

const Mission = () => {

  const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener('change', handleChange);

    return () => darkModeMediaQuery.removeEventListener('change', handleChange);
  }, []);

  return (
    <>
      
      <div className='mission'>
        <div className="logo_wrapper">
          <img className="logo-block" src={isDarkMode ? logoBlockDark : logoBlock} alt="Revibe Logo" />
        </div>

        <h1>Mission Statement</h1>
        <div>
          <h5>Refresh life and experiences with Revibe</h5>
          <p>
            It's our goal to make a space to share, learn, and boost each other up in a straightforward and imaginative way. We're here to share good vibes, fire up creativity, make learning fun, and spark new ways of doing things. Together, we're shaping a future that's about what we all care about.
          </p>
        </div>
        <h4>Overview</h4>
        <p>
          Revibe is creating a spot for making connections, learning new stuff, and being kind every step of the way.
        </p>
        <p>
          Our focus is on keeping things real while prioritizing what really matters to you.
        </p>
        <p>
          Join us! Share your thoughts, your stories, and let's build a platform that's really about all of us.
        </p>
        <C2AMessage
            headline="We're simplifying online interactions to create a new vibe"
        />
        <p>
            We use clear language, target the heart of problems, and celebrate the cool and different things that unite us. We're crafting a place on the web where every click makes your day a little better. By blending smart design with some neat psychological insights, we're striving to make our online experience not only easy but really engaging.
        </p>
        <C2AMessage
            headline=" Revibe is a new vibe!"
            cost="We're changing the online scene to focus more on fun, understanding, and support"
        />
        <div>
          <h5>Building Connections</h5> 
          <p>
            We're making it super simple for you to meet others, share your stories, and feel like you're part of a bigger picture.
          </p>
          <h5>Discovering</h5> 
          <p>
            We provide straightforward paths for you to explore new ideas and celebrate our unique differences.
          </p>
          <h5>Innovating</h5> 
          <p>
            We're constantly refining our tech to keep up with your preferences, ensuring everything runs as smoothly as possible and stays fun.
          </p>
          <h5>Spreading Good Vibes</h5> 
          <p>
            Our goal is to cultivate a welcoming space, with a focus on enjoying our time together and getting along well.
          </p>
        </div>

        <img src={mission_group} />

        <h4>Revibe with AI</h4>
        <p>
          Our team and AI work hand in hand. We are a group of friendly unique people with differing views. Together, we use AI, kind of like a smart tool, to help make cool stuff for you. We try our best to make sure Revibe feels just right for you — from the fun pictures and helpful tips to all the words you read here — and we'll keep trying to get better when we fail! We guide the AI, teaching it about our vision for Revibe, and in turn, it helps us create cool stuff quickly, and in a safer way. It's not just about making things easier; it's about adding that extra spark of creativity so that Revibe continues to be a source of great vibes. This teamwork between us and AI means we're always building things. We hope to surprise you with something new and exciting, keeping things simple, friendly, and super useful for you.
        </p>

        <h4>A Nice Vibe, Together</h4>
        <p>
          Revibe's mission is to light up our online space in a way that makes the day a bit brighter. This place is built on respect, sharing, and good conversations. To keep the good vibes going, we need to look out for each other and keep things friendly and supportive. If you spot anything that messes with our chill vibe, please reach out to us! Your help is crucial in making Revibe a spot where good vibes and friendliness grow. Let's team up to make our online world a happier and more welcoming place, one chat at a time.
        </p>


        <C2AMessage
            headline="How's everything going?"
        />
        <p>
          If something is wrong or goes against the good vibes we're all about, please get in touch at <a href="mailto:support@revibe.life">support@revibe.life</a>.
        </p>
        <h4>Frequently Asked Questions (FAQs)</h4>
        <p>We understand you may have questions. Our FAQs section is tailored to enhance understanding. <a href="/faqs">See our FAQs</a></p>
        <LegalItem
            href="/faqs"
            title="FAQs"
            description="Get Common Answers" />

        <h4>Privacy and Security Measures</h4>
        <p>We prioritize your privacy and the security of your data.</p>
        <LegalItem
            href="/privacy"
            title="Privacy Policy"
            description="Practices with respect to personal information we collect." />
        <LegalItem
            href="/cookies"
            title="Cookie Policy"
            description="Information regarding our use of cookies." />

        <h4>We value your voice</h4>
        <p>Send us a message, and we'll try our best to assist you.</p>
        <h5 style={{padding: "2rem 0"}}>
          <a href="mailto:support@revibe.life">support@revibe.life</a>
        </h5>
        <C2AMessage
            headline="Reach out to us by sending us a message"
        />

      </div>
      
      <div className='event_nav' style={{zIndex: 0}}>
          <Link to={`/resources/`}>
            <div className="fa-button">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    size={"xl"}
                />
            </div>
          </Link>
      </div>
    </>
  );
};

export default Mission;
