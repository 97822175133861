/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import Papa from 'papaparse';
import './CSVUploader.css';

const CSVUploader = ({ onDataUpload, onWarningMessage }) => {
  const [fileName, setFileName] = useState('');

  const handleFileUpload = (event) => {
    onWarningMessage(''); // Clear previous warning in the parent
    const file = event.target.files[0];
    setFileName(file.name);

    Papa.parse(file, {
      header: true,
      complete: (result) => {
        let data = result.data;
        if (data.length > 100) {
          data = data.slice(0, 100);
          onWarningMessage('File contains more than 100 rows. Only the first 100 rows will be processed.');
        }
        onDataUpload(data);
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error);
        onWarningMessage('Error parsing the CSV file.');
      },
    });
  };

  return (
    <div className="csv-uploader">
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
      />
      {fileName && <p>Uploaded: {fileName}</p>}
    </div>
  );
};

export default CSVUploader;
