/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import config from '../config';

const api = config.createApiUrl('directory', `/org/public/`, config.URL_TYPES.API);

export const OrganizationContext = createContext({
    fetchOrganizationById: () => { console.warn('fetchOrganizationById was called without a Provider') },
  });  
  
export const useOrganizations = () => {
  return useContext(OrganizationContext);
};

export const OrganizationProvider = ({ children }) => {
    const [organizations, setOrganizations] = useState(() => {
        const savedOrgs = localStorage.getItem('organizations');
        return savedOrgs ? new Map(JSON.parse(savedOrgs)) : new Map();
      });

    const fetchOrganizationById = useCallback((id) => {
        if (organizations.has(id)) return;
      
        axios.get(`${api}${id}`)
          .then(response => {
            const organization = response.data;
            const updatedOrganizations = new Map(organizations).set(id, organization);
            setOrganizations(updatedOrganizations);
            localStorage.setItem('organizations', JSON.stringify(Array.from(updatedOrganizations.entries())));
          })
          .catch(err => {
            console.error(err);
          });
      }, [organizations]);
      

    return (
        <OrganizationContext.Provider value={{ organizations, fetchOrganizationById }}>
            {children}
        </OrganizationContext.Provider>
    );
};
  
