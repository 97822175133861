/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './QuickCreate.css';

import { Link } from 'react-router-dom';

import C2AMessage from '../C2AMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faCirclePlus, faRotate } from '@fortawesome/free-solid-svg-icons';

import config from '../../config';
import ErrorMessage from '../ErrorMessage';
import Loading from '../Loading';

const DIRECTORY_BASE_URL = config.createApiUrl('directory', '/groups/', config.URL_TYPES.BASE);

const QuickCreate = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [primaryOrg, setPrimaryOrg] = useState(null);

  useEffect(() => {
    const api = config.createApiUrl('directory', `/org/primary/`, config.URL_TYPES.API);
    
    try {
      axios.get(`${api}`)
        .then(response => {
        setPrimaryOrg(response.data);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err)
        if (err.response?.status == 404) {
          setPrimaryOrg(null);
        } else {
          setErrorMessage('There was an error loading your primary group. Try again in a few minutes.');
        }
        setIsLoading(false);
      });
    } catch {
      setErrorMessage('There was an error loading your primary group. Try again in a few minutes.');
      setIsLoading(false);
    }
  }, []);

  // IDEA: Refactor to a common utility
  function truncateCharacters(str, maxLength) {
      if (str.length <= maxLength) {
        return str; // Returns original string if string is equal to or less than max length
      } else {
        const truncatedString = str.substring(0, maxLength); // Get the substring up to the maxLength
        // Find the last space within the truncated substring to avoid cutting off a word
        const lastSpaceIndex = truncatedString.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
          return truncatedString.substring(0, lastSpaceIndex) + '...'; // Add dots at the last space
        } else {
          return truncatedString + '...'; // If no space found, add dots at the end of the substring
        }
      }
  }

  const groupName = primaryOrg?.name ? primaryOrg?.name : 'Create a free group'

  if (!isLoading && errorMessage) {
    return (
      <div className='quick_create-widget'>
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className='quick_create-widget'>
        <Loading />
      </div>
    )
  }

  return (
    <div className='quick_create-widget'>
      <>
          <div className='connected-event_group'>
              <div className='connected-event_group-info'>
                  <h6 className='connected_vibe_label'>
                      {truncateCharacters(groupName, 24)}
                  </h6>
              </div>

              { primaryOrg && 
                <Link to={`${DIRECTORY_BASE_URL}`}>
                    <div className='button gradient_button'>
                        <FontAwesomeIcon icon={faRotate} />
                        Switch
                    </div>
                </Link>
              }
              { !primaryOrg && 
                <Link to={`${DIRECTORY_BASE_URL}new`}>
                    <div className='button gradient_button'>
                        <FontAwesomeIcon icon={faCirclePlus} />
                        Create
                    </div>
                </Link>
              }
          </div>
          <div className='gradient-border' />
      </>
      { primaryOrg &&
        <div className='quick_create-widget-main_button_wrapper'>
          <Link to={`${DIRECTORY_BASE_URL}${primaryOrg.organization_id}/newevent`}>
            <div className='button'>
                <FontAwesomeIcon icon={faCalendarPlus} />
                Add Event
            </div>  
          </Link>
        </div>
      }
      { !primaryOrg && 
        <C2AMessage
            headline="Amplify events and vibes"
            actionText="Create a group"
            actionLink={`${DIRECTORY_BASE_URL}new`}
            features="Then, subscribe to create events and other vibes"
        />
      }
      <div className='quick_create-subtext'>
        What vibe will you energize?
      </div>
    </div>
  );
};

export default QuickCreate;
