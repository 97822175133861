/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Papa from 'papaparse';

import Loading from '../../components/Loading';

import config from '../../config';

import CSVUploader from '../../components/CSVUploader';
import ResultsTable from '../../components/ResultsTable';

import ErrorMessage from '../../components/ErrorMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import './EventManagement.css'
import UnixConverter from '../../components/UnixConverter/UnixConverter';

const EventManagement = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  

  const [selectedRows, setSelectedRows] = useState([]);
  const [isBatchActionDisabled, setIsBatchActionDisabled] = useState(false);

  const [isConverterHidden, setIsConverterHidden] = useState(false);

  useEffect(() => {
    const api = config.createApiUrl('events', `/event_management/`, config.URL_TYPES.API);
    // Fetch user data from API
    setIsLoading(true);
    axios.get(api)
      .then(
        // Do Nothing, this api call is really just checking for admin access
        // and the backend knows the user identity (but the UI does not)
        // the UI knows how to re-route when getting the 40X code
      )
      .catch(error => {
        console.error('Error fetching user data:', error);
        setErrorMessage("Sorry, this is closed to the public")
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  
  const handleDataUpload = (uploadedData) => {
    setData(uploadedData);
  };

  const handlePrimaryAction = (row) => {
    const api = config.createApiUrl('events', `/event_management/`, config.URL_TYPES.API);
    axios.post(api, row)
      .then(response => {
        console.log('Action performed:', response.data);
        setData(prevData => prevData.filter(r => r !== row));  // Remove the successful row
      })
      .catch(error => {
        console.error('Error performing action:', error);
      
        // Update the row with the error flag
        setData(prevData => prevData.map(r => 
          r === row ? { ...r, error: true } : r
        ));
      });
  };

  const handleBatchAction = (selectedRows) => {
    if (isBatchActionDisabled) return;
  
    const api = config.createApiUrl('events', `/event_management/batch`, config.URL_TYPES.API);
    
    // Transform selectedRows into the required format
    const selectedRowObjects = data.filter(row => selectedRows.includes(row.event_name));
  
    axios.post(api, selectedRowObjects)
      .then(response => {
        const successfulEvents = response.data.successful_events || [];
        const failedEvents = response.data.failed_events || [];
    
        // Remove successful events from the table
        setData(prevData => prevData.filter(row => 
          !successfulEvents.some(success => 
            success.event_data && success.event_data.event_name === row.event_name
          )
        ));
    
        // Highlight failed events in the table
        setData(prevData => prevData.map(row => {
          if (failedEvents.some(failed => 
            failed.event_data && failed.event_data.event_name === row.event_name
          )) {
            return { ...row, error: true };
          }
          return row;
        }));
    
        console.log('Batch action completed:', response.data.message);
      })
      .catch(error => {
        console.error('Error performing batch action:', error);
        // Optional: Mark all rows as failed in case of a batch-level failure
        setData(prevData => prevData.map(row => {
          if (selectedRows.includes(row.event_name)) {
            return { ...row, error: true };
          }
          return row;
        }));
      })
      .finally(() => {
        setIsBatchActionDisabled(true);
        setTimeout(() => setIsBatchActionDisabled(false), 3000); // 3-second cooldown
      });
  };
      
  const handleDownload = () => {
    // Get all unique keys from the data to ensure all columns are included
    const allKeys = Array.from(new Set(data.flatMap(Object.keys)));
  
    // Explicitly define the fields for the CSV, ensuring all columns are included
    const csvData = data.map(row => {
      const fullRow = {};
      allKeys.forEach(key => {
        fullRow[key] = row[key] || ''; // Ensure all keys are present, with empty strings for missing values
      });
      return fullRow;
    });
  
    const csv = Papa.unparse(csvData, { columns: allKeys });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "remaining_events.csv");
    document.body.appendChild(link);
    link.click();
  };

  const NavWrapper = ({children}) => {
      return (
          <>
              {children}
              {/* Nav */}
              <div className='event_nav' style={{zIndex: 0}}>
              <Link to="/" className='fa-button search_nav-back'>
                  <FontAwesomeIcon
                      icon={faArrowLeft}
                      size="xl" />
              </Link>
            </div>
          </>
      )
  }

  if (errorMessage) {
      return (
          <NavWrapper>
              <ErrorMessage errorMessage={errorMessage} />
              <Link to="/" className='button'>
                <div style={{marginRight: "0.25rem"}}>
                      <FontAwesomeIcon
                          icon={faArrowLeft} />
                  </div>
                  Back
              </Link>
              <div className='event_nav'>
                  <Link to="/" className='fa-button nav-back'>
                      <FontAwesomeIcon
                          icon={faArrowLeft}
                          size="xl" />
                  </Link>
              </div>
          </NavWrapper>
      )
  }

  if (isLoading) {
      return (
          <NavWrapper>
              <Loading />
          </NavWrapper>
      )
  }

  return (
    <NavWrapper>
      <div>
        <div className='converterRow'>
          <div className='button' onClick={() => setIsConverterHidden(!isConverterHidden)}>
            {isConverterHidden ? 'Hide' : 'Show'} Converter
          </div>
          
          { isConverterHidden &&
            <UnixConverter />
          }
        </div>

        <div className='event_management-actions'>
          {selectedRows.length > 0 && 
            <div className={`button ${isBatchActionDisabled ? 'disabled' : ''}`} disabled={isBatchActionDisabled} onClick={() => handleBatchAction(selectedRows)}>
              Batch Action
            </div>
          }
          
          {data.length > 0 && 
            <div className='button' onClick={handleDownload}>
              Download Remaining Rows
            </div>
          }
        </div>
        
        <CSVUploader 
          onDataUpload={handleDataUpload} 
          onWarningMessage={setWarningMessage} 
        />
        {warningMessage && <ErrorMessage errorMessage={warningMessage} />}
        {data.length > 0 && (
          <ResultsTable
            data={data}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            onPrimaryAction={handlePrimaryAction}
          />
        )}
      </div>
    </NavWrapper>
  );
};

export default EventManagement;
