/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Event from '../Event';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import ErrorMessage from '../../components/ErrorMessage';
import C2AMessage from '../../components/C2AMessage';

import './QueryEventCalendar.css';

const QueryEventCalendar = ({ events, onDateChange, isFirstRender, eventServiceDown }) => {
  const [activeEvent, setActiveEvent] = useState(null)

  const fullCalendarEvents = events.map(event => ({
    ...event,
    event_id: event.id,
    title: event.event_name,
    start: new Date(event.start_date),
    end: new Date(event.end_date),
  }));

  const chooseCalendarEvent = (data) => {
      setActiveEvent(data.event._def.extendedProps)
  }

  // Render full title/color for activeEvent (clicked event)
  const isActiveEvent = (data) => {
    if (activeEvent?.event_id == data.event._def.extendedProps.event_id) {
      return [data.event.title, 'active']
    }
    return [data.event.title, '']
  }

  // A custom render function
  const renderEventContent = (eventInfo) => {
      return (
        <div className={`event-calendar_item ${isActiveEvent(eventInfo)[1]}`} 
            onClick={() => chooseCalendarEvent(eventInfo)}>
            {isActiveEvent(eventInfo)[0]}
        </div>
      )
  }

  const dateChanged = (dateInfo) => {
    if (onDateChange && !isFirstRender) {
      onDateChange(dateInfo);
    }
  
    /**
     * Also, a reminder, that the 'StrictMode' causes odd behavior that 
     * should NOT show up in production when 'StrictMode' is off
     * 
     * So, do not try to fix the absolute positioning
     **/
  };

  return (
    <div>
      <div>
        <FullCalendar
          plugins={[ dayGridPlugin ]}
          initialView='dayGridWeek'
          weekends={true}
          events={fullCalendarEvents}
          eventContent={() => renderEventContent}
          datesSet={dateChanged}
          />
      </div>
      
      {fullCalendarEvents.length == 0 &&
        <>
          <div className='error-message no-events'>
            {eventServiceDown ? 'Unable to load events. Please try again later' : 'Weekly Revibe: No Events'}
          </div>
        </>
      }

      {activeEvent &&
      <>
        <h6 className='disclaimer'>
          Disclaimer: Event visuals use <br />AI-generated stock images.
        </h6>
        <Event id={activeEvent.event_id} {...activeEvent} />
      </>
      }

      {!activeEvent && fullCalendarEvents.length > 0 &&
        <h5 className='calendar-microcopy'>Choose a calendar event</h5>
      }
    </div>
  )
  
}

export default QueryEventCalendar;
