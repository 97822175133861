/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Event from '../Event';
import Toggle from '../../components/Toggle';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import ErrorMessage from '../../components/ErrorMessage';
import C2AMessage from '../../components/C2AMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartCrack, faHeart, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import './EventCalendar.css';
import QuickCreate from '../QuickCreate/QuickCreate';

const EventCalendar = ({ events, onDateChange, setShowAll, showAll }) => {
  const [activeEvent, setActiveEvent] = useState(null)
  const [showInfo, setShowInfo] = useState(false)

  const fullCalendarEvents = events.map(event => ({
    ...event,
    event_id: event.id,
    title: event.event_name,
    start: new Date(event.start_date),
    end: new Date(event.end_date),
  }));

  const chooseCalendarEvent = (data) => {
      setActiveEvent(data.event._def.extendedProps)
  }

  // Render full title/color for activeEvent (clicked event)
  const isActiveEvent = (data) => {
    if (activeEvent?.event_id == data.event._def.extendedProps.event_id) {
      return [data.event.title, 'active']
    }
    return [data.event.title, '']
  }

  // A custom render function
  const renderEventContent = (eventInfo) => {
      return (
        <div className={`event-calendar_item ${isActiveEvent(eventInfo)[1]}`} 
            onClick={() => chooseCalendarEvent(eventInfo)}>
            {isActiveEvent(eventInfo)[0]}
        </div>
      )
  }

  const dateChanged = (dateInfo) => {
    if (onDateChange) {
      onDateChange(dateInfo);
    }
  
    /**
     * Also, a reminder, that the 'StrictMode' causes odd behavior that 
     * should NOT show up in production when 'StrictMode' is off
     * 
     * So, do not try to fix the absolute positioning
     **/
  };

  return (
    <div className='event-calendar'>
      <FullCalendar
        plugins={[ dayGridPlugin ]}
        eventOverlap={false}
        initialView='dayGridMonth'
        weekends={true}
        events={fullCalendarEvents}
        eventContent={renderEventContent}
        datesSet={dateChanged}
      />
      
      <QuickCreate />

      <div className='toggle_group'>
        <div className='toggle_group-button_row'>
          <div className="full_details_button" onClick={() => setShowInfo(!showInfo)}>
            <FontAwesomeIcon
                icon={!showInfo ? faCircleQuestion : faCircleXmark}
                size={"lg"}
                />
            {!showInfo ? `Learn More` : `Hide Details`}
          </div>
          <Toggle setShowAll={setShowAll} showAll={showAll}/>
        </div>
        {showInfo &&
          <>
            <C2AMessage
                headline="Try out the tutorial!"
                actionText="Learn more"
                actionLink={`/tutorial`}
                features="View some practice vibes"
            />
            <h6>
              See your current vibes ( <span><FontAwesomeIcon icon={faHeart} /></span> ) or switch to explore all vibes including the ones where your feelings changed ( <span><FontAwesomeIcon icon={faHeartCrack} /></span> )
            </h6>
            <h6>
            Change your vibe  ( <span><FontAwesomeIcon icon={faHeart} /></span> or <span><FontAwesomeIcon icon={faHeartCrack} /></span> ) to personalize your calendar. Changes appear when you use the toggle or switch months.
            </h6>

            <h6>
              Disclaimer: Event visuals use <br />AI-generated stock images.
            </h6>
          </>
        }
      </div>
      
      {activeEvent &&
        <>
          <Event id={activeEvent.event_id} {...activeEvent} />
        </>
      }
      {!activeEvent && fullCalendarEvents.length > 0 &&
        <>
          <h6 className='calendar-microcopy'>
              Choose an event from your calendar to see the details here
          </h6>
          <C2AMessage
              headline="Looking for a new vibe instead?"
              actionText="Explore"
              actionLink={`/search`}
              features="Search made simple."
              cost="Easily find fresh vibes!"
          />
        </>
      }
      {!activeEvent && fullCalendarEvents.length == 0 &&
        <>
          <ErrorMessage errorMessage={'Empty Calendar? Find a new vibe!'} />
          <C2AMessage
              headline="Vibes show up on your calendar"
              actionText="Explore"
              actionLink={`/search`}
              features="Search made simple."
              cost="Easily find fresh vibes!"
          />
        </>
      }
    </div>
  )
  
}

export default EventCalendar;
