/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Button } from '@mui/material';
import Modal from 'react-modal';

import './ResultsTable.css';

const ResultsTable = ({ data, selectedRows, setSelectedRows, onPrimaryAction }) => {
  const [modalData, setModalData] = useState(null);

  const handleSelectRow = (row) => {
    setSelectedRows(prevSelected =>
      prevSelected.includes(row.event_name)
        ? prevSelected.filter(name => name !== row.event_name)
        : [...prevSelected, row.event_name]
    );
  };

  const handleViewDetails = (row) => {
    setModalData(row);
  };

  return (
    <div id="results_table">
      <div id='main_table_wrapper'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
                  checked={selectedRows.length === data.length}
                  onChange={(e) => setSelectedRows(e.target.checked ? data.map(row => row.event_name) : [])}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} className={row.error ? 'failed-row' : ''} selected={selectedRows.includes(row.event_name)}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(row.event_name)}
                    onChange={() => handleSelectRow(row)}
                  />
                </TableCell>
                <TableCell>{row.event_name}</TableCell>
                <TableCell>
                  <Button onClick={() => handleViewDetails(row)}>View Details</Button>
                  <Button onClick={() => onPrimaryAction(row)}>Primary Action</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {modalData && (
        <Modal
          isOpen={!!modalData}
          onRequestClose={() => setModalData(null)}
          contentLabel="Details Modal"
        >
          <h2>Details</h2>
          <pre>{JSON.stringify(modalData, null, 2)}</pre>
          <Button onClick={() => setModalData(null)}>Close</Button>
        </Modal>
      )}
    </div>
  );
};

export default ResultsTable;
