/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';

import LineItem from '../../components/LineItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

import './Section.css'

// Similarly, define Section outside EditPage
const Section = ({ 
    title,
    items,
    section,
    onAmountChange,
    onAddLineItem,
    onRemoveItem,
    isEditing,
    onLabelCaptionChange,
    showAddCategory,
}) => {
    return (
        <div className='section'>
            <h2>{title}</h2>
            {items.map((item, index) => (
                <LineItem
                    index={index}
                    key={`${section}-${index}`}
                    section={section}
                    item={item}
                    onAmountChange={onAmountChange}
                    isEditing={isEditing}
                    onLabelCaptionChange={onLabelCaptionChange}
                    onRemoveItem={onRemoveItem}
                />
            ))}
            {showAddCategory &&
                <div className='PositionIcon'>
                    <a
                        onClick={() => onAddLineItem(section)}>
                            <FontAwesomeIcon icon={faCirclePlus} className='space'/>
                            Add Category
                    </a>
                </div>
            }
        </div>
    );
};

export default Section;
