/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import { Link } from 'react-router-dom';

const C2AMessage = ({ headline, actionText, actionLink, features, cost }) => {
  return (
      <div className='c2a'>
        {headline && <h6>{headline}</h6>}
        <b>
          {actionText && actionLink && 
                  <Link className="full_width" to={actionLink}>
                      <div className="button gradient_button">
                          {actionText}
                      </div>
                  </Link>
          }
          {features && <br />}
          {features && <span>{features}</span>}
          {cost && <br />}
          {cost && <span>{cost}</span>}
        </b>
      </div>
  );
};

export default C2AMessage;
