/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

import './LocalLinks.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars, faEllipsis, faLightbulb, faRocket, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash, faArrowUpFromBracket, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faEye, faMessage, faCommentDots, faSmileWink } from '@fortawesome/free-regular-svg-icons';

import ErrorMessage from '../ErrorMessage';

import ChipScroll from '../../components/ChipScroll';
import LocalLinksBarChart from '../../components/LocalLinksBarChart';

import Loading from '../Loading';

import config from '../../config';

// IDEA: Refactor these lookups to a common directory
const iconLookup = {
  inquire: faBinoculars,
  appreciate: faThumbsUp,
  innovate: faLightbulb,
  enhance: faRocket,
};

const termLookup = {
  inquire: 'Explore',
  appreciate: 'Value',
  innovate: 'Renew',
  enhance: 'Boost',
}

// IDEA: Read this from a static file in an S3 bucket
// and, the S3 bucket file gets updated on deploy
// based on the database schema
const forumMappingReasons = {
  inquire: [
    "Examine Events",
    "Explore Effects",
    "Clarify Concepts",
    "Determine Details",
  ],
  appreciate: [
    "Value Views",
    "Honor Highlights",
    "Salute Specifics",
    "Applaud Actions",
  ],
  innovate: [
    "Rethink Reasons",
    "Invent Ideas",
    "Nurture Novelty",
    "Craft Concepts",
  ],
  enhance: [
    "Elevate Energy",
    "Boost Bonds",
    "Build Bridges",
    "Reinforce Results",
  ]
}

// END Lookup Data

const forumURL = config.createApiUrl('forum', `/forum/`, config.URL_TYPES.BASE)
const eventForumAPI = config.createApiUrl('events', `/forum/`, config.URL_TYPES.API)

const LocalLinks = ({ event_id, event_title }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [forumWidgetOpen, setForumWidgetOpen] = useState(false);
  const [vibeType, setVibeType] = useState(null);
  const [relatedReason, setRelatedReason] = useState(null);

  const [comment, updateComment] = useState('');
  const [hint, showHint] = useState('')

  // Request Data
  const [mappings, setMappings] = useState([]);
  const [stats, setStats] = useState([]);

  const fetchEventForumMappings = () => {
    setIsLoading(true);
    axios.get(`${eventForumAPI}`, {
      params: { event_id: event_id } // Include event_id in the request if available
    })
    .then(response => {
      setMappings(response.data);
      setIsLoading(false);
    })
    .catch(error => {
      console.error("Error fetching event forum mappings:", error);
      setErrorMessage("Failed to load data");
      setIsLoading(false);
    });
  };

  // Fetch event forum mappings
  useEffect(() => {
    fetchEventForumMappings();
  }, []);

  const reactionClick = (reaction) => {
      setVibeType(reaction);
      if (reaction !== vibeType) {
        setRelatedReason(null);
      }
  };

  const sendNewVibe = async () => {
      setIsLoading(true);
      try {
        const payload = {
          event_title: event_title,
          event_id: event_id,
          vibe_type: vibeType,
          related_reason: relatedReason,
          comment: comment
        }
        
        axios.post(`${eventForumAPI}`, payload)
        .then(response => {
          // IDEA: Handle response, success message?, update section below?
          fetchEventForumMappings(); // Reuse the fetch function to update mappings
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
              setErrorMessage('Oops, there might be a glitch. Please contact support.');
            } else if (err.response.status === 429) {
              setErrorMessage('Take some time to participate in a vibe below');
            } else if (err.response.status === 500) {
              setErrorMessage('There was an error. Try again in a few minutes.');
            }
            else {
              setErrorMessage(err.response.message);
            }
          } else {
            setErrorMessage('There was an error. Try again in a few minutes.');
          }
        }).finally(() => {
          setIsLoading(false);
          setVibeType(null)
          setRelatedReason(null);
          updateComment('');
          setForumWidgetOpen(false);
        });

      } catch (err) {
        setErrorMessage('There was an error. Try again in a few minutes.');
      }
  }

  /* 
   * IDEA: Add ability to create a forum from event service 
   * 
   * Figure out the specific easy way to handle a "reaction_click" (forumMappingReasons)
   * These become conversation starters about events
   */
  return (
    <div className="local-links">
      <h6 className='disclaimer'>
        Disclaimer: Event visuals use <br />AI-generated stock images.
      </h6>

      {isLoading &&
        <div className='forum-widget is_loading'>
            <Loading />
        </div>
      }

      {!forumWidgetOpen &&
       !isLoading &&
        <div 
          className='forum-widget entry'
          onClick={() => {
              setForumWidgetOpen(true)
              setErrorMessage(null)  
            }
          }>
            <h5>
              <FontAwesomeIcon
                icon={faMessage}
                size={"lg"}
              />
              Revibe the Vibe!
            </h5>
            <h6>
                Select this to start a chat
            </h6>
        </div>
      }
      
      {/* IDEA: Move the forumWidget into a new component */}
      {forumWidgetOpen &&
       !isLoading &&
        <div className='forum-widget'>
            { !vibeType &&
              <h6>
                  <strong>What is your vibe?</strong>
              </h6>
            }

            { vibeType &&
              <h4>
                <FontAwesomeIcon icon={iconLookup[vibeType]} size="xl" />
                {termLookup[vibeType]} the Vibe!
              </h4>
            }
            { vibeType &&
              <div>
                { vibeType && !relatedReason &&
                  <h6>
                    How do you want to start?
                  </h6>
                }
                { vibeType && relatedReason &&
                  <h6>
                    Share your thoughts!
                  </h6>
                }
                <ChipScroll
                  goodVibes={forumMappingReasons[vibeType]}
                  updateComment={updateComment}
                  setRelatedReason={setRelatedReason}
                  selectedReason={relatedReason}
                />
              </div>
            }
            <div className='row revibe_actions'>
                <div onClick={() => reactionClick('appreciate')}>
                    <FontAwesomeIcon icon={faThumbsUp} size="xl" />
                </div>

                <div onClick={() => reactionClick('inquire')}>
                    <FontAwesomeIcon icon={faBinoculars} size="xl" />
                </div>

                <div onClick={() => reactionClick('innovate')}>
                    <FontAwesomeIcon icon={faLightbulb} size="xl" />
                </div>

                <div onClick={() => reactionClick('enhance')}>
                    <FontAwesomeIcon icon={faRocket} size="xl" />
                </div>
            </div>
            <div className='quick-comment'>
                <div className='quick-comment-actions'>
                    <div onClick={() => setForumWidgetOpen(false)}>
                        <FontAwesomeIcon icon={faArrowUpFromBracket} size='xl'/>
                    </div>
                    { !vibeType &&
                      <div>
                        Select an icon above
                      </div>
                    }
                    { vibeType && !relatedReason &&
                      <div>
                        Let's make a plan!
                      </div>
                    }
                    {vibeType && relatedReason &&
                      <>
                        <div className="search_input-container">
                            <textarea 
                                type="text"
                                value={comment} 
                                onChange={(e) => {
                                  showHint(false)  
                                  updateComment(e.target.value)
                                }}
                                placeholder={'A comment starts the chat!'}
                                />
                        </div>
                        <div onClick={() => {
                          if (comment.length >= 2 && comment.length <= 200) {
                            sendNewVibe();
                          }
                        }}>
                        {
                          (comment.length >= 2 && comment.length <= 200) ?
                          <FontAwesomeIcon icon={faPaperPlane} size="xl" /> :
                          <FontAwesomeIcon onClick={() => showHint(true)} icon={faCommentDots} size="xl" />
                        }
                        </div>
                      </>
                    }
                </div>
                <div className='forum-widget-comment_hint'>
                  {  comment.length <= 200 &&
                     comment.length > 122 &&
                    <div>
                      <FontAwesomeIcon icon={faSmileWink} size="lg" />
                      You have { 200 - comment.length } characters left
                    </div>
                  }
                  { comment.length > 200 && <div>Try to write a shorter comment (Remove {Math.abs(200 - comment.length)} characters)</div>}
                  { hint &&
                    comment.length <= 200 &&
                    <div>
                      { comment.length <= 2 && 'Try to write a comment with at least two characters'}
                    </div>
                  }
                </div>
            </div>
        </div>
      }

      {errorMessage &&
        <ErrorMessage errorMessage={errorMessage} />
      }

      {/* IDEA: REFACTOR Sections into components */}
          <h4>Related Discussions</h4>
          <h5>Reveal What Connects Us</h5>

      <div className='forum_group'>
          {mappings.length < 1 &&
            <h6 className='disclaimer'>
              New vibes will show up here
            </h6>
          }
          {mappings.map((d, i) => {
            return (
              <div key={d.id} className='forum_info_item'>
                <div className='forum_info_group'>
                  <div className='forum_vibe_label'>
                    <FontAwesomeIcon
                        icon={iconLookup[d.vibe_type.type]}
                    />
                  </div>
                  <div className='forum_reason_label'>
                    {d.related_reason.reason}
                  </div>
                </div>
                <div>
                  <Link to={`${forumURL}${d.forum_id}`} >
                    <span className='link_to_forum'>
                      <FontAwesomeIcon
                          icon={faEllipsis}
                      />
                    </span>
                  </Link>
                </div>
                {/* <p className='event_org_info_value'>
                  <span className='recent_comment_from_forum'>
                    {d.related_reason.reason}
                  </span>
                  
                </p> */}
              </div>
            )
          })}
      </div>

      { mappings?.length === 10 &&
        <>
          <h6 className='disclaimer'>
            Discover 10 recent Revibe Forums above, <br/> or interact with the graph below
          </h6>
        
          <h4>Vibe Summary</h4>
          <h5>Insights About This Event</h5>
          <h6>Select each element for more details</h6>

          <LocalLinksBarChart event_id={event_id} />
        </>
       }
    </div>
  );
};

export default LocalLinks;
